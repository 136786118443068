import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';

import {getGlid, geturldata, glidtracker, gatrack, getGstData } from '../../utils';

import HeaderComponent from '../header/HeaderComponent';

// import indifi from '../../Images/lenderimages/Indifi_logo-01.png'
// import icici from '../../Images/lenderimages/ICICI_Bank_Logo-01.jpg'
import offer from '../../Images/lenderimages/offer-svgrepo-com.svg'
// import iifl from '../../Images/lenderimages/IIFL-New-Common-logo.png'
// import lkart from '../../Images/lenderimages/lendingkartlogo-.svg'
// import creditenable from '../../Images/lenderimages/CreditEnable_Logo_Dark_Blue.png'
// import andromeda from '../../Images/lenderimages/Andromeda.jpeg(2)(1).jpg'
// import psb from '../../Images/lenderimages/PSB59-Logo-for-IndiaMart.png'
// import creditmantri from '../../Images/lenderimages/CM-Logo-1920.png'
import oneinfinity from '../../Images/lenderimages/OI_logo.jpg'
// import loantap from '../../Images/lenderimages/HQ_LoanTap_logo_.png'
import poonawalla from '../../Images/lenderimages/PFL-White-Logo.jpg'
import tataCapital from '../../Images/lenderimages/tataCapital.png'
import indialends from '../../Images/lenderimages/IL.svg'
import sad_face from '../../Images/applicationsImages/sad_face.png';

//import offer from 
var vintage_ranged_val = ["Upto 1 Year","1-3 Years","Above 3 Years"]; 
var vintage_actual_val = ["12","36","40"];

var turnover_ranged_val = ["Upto 10 Lakh","10-25 Lakh","25-40 Lakh","40 Lakh-1.5 Cr","1.5-5 Crore","Above 5 Cr"];
var turnover_actual_val = ["10","25","40","150","500","520"];

var loanamt_ranged_val = ["Upto 5 Lakh","5-10 Lakh","10-20 Lakh","Above 20 Lakh"];
var loanamt_actual_val = ["5","10","20","40"];


function getcookieready(dataarray) {
    const formattedString = Object.entries(dataarray)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    return formattedString;
}

function clicktracking(vendor_name,self) {

    var glid = getGlid();

    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : self.state.lenderOrder,
        "action" : "Apply Now Click",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }

}

function clicktrackingCommon (vendor_name,lenderorder,action) {

    var glid = getGlid();

    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : (lenderorder !== '') ? lenderorder : "NA",
        "action" : (action !== '') ? action : "NA",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };
    
    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {   
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }
}

const headingstyle = {
    color: 'var(--IM-Black, #1B1D1D)',
    fontFamily: 'Arial',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'capitalize',
}

const modifydetailsstyle = {
    textAlign : 'right',
   // paddingRight : '185px',
    color: 'var(--Green-P, #02A699)',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    // cursor: 'pointer',
    margin:'auto'
}

const loanamountcaptionstyle = {
    display:'none',
    color: 'var(--Grey-3, #757575)',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    marginLeft: '0px',
    marginTop:'-9px',
    marginBottom:'3px'
}

const loantypecaptionstyle = {
    display:'none',
    color: 'var(--Grey-3, #757575)',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    marginLeft: '0px',
    marginTop:'-9px',
    marginBottom:'3px'
}

const pincodecaptionstyle = {
    color: '#F1F1F1',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    marginLeft: '10px',
    marginTop:'-25px',
    marginBottom:'3px'
}

const pincodeinputstyle = {
    width: '100%', 
    fontFamily: 'Arial', 
    fontSize: '14px', 
    color: '#000', 
    backgroundColor:'inherit', 
    marginLeft:'-55px', 
    marginTop:'0px', 
    textAlign:'left' 
}

const modalheaderstyle = {
    color: 'var(--IM-Black, #1B1D1D)',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    padding:'20px'
}

const greyLineStyle = {
    width:'677px',
    marginLeft:'-20px',
    marginTop:'6px'
  }

const modalsavebutton = {
    display: 'flex',
    width: '120px',
    height: '40px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '4px',
    background: '#2AA699',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Arial',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    cursor:'pointer'
}  

const loanamtinputbox = {
    display: 'flex',
    padding: '14px 30px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '6px',
    border: 'none',
    width:'140px',
    height: '50px'
}

const oneinfstyle = {
    paddingLeft:'15px',
    paddingRight:'25px'
}


class LenderComponent extends Component
{
    constructor(props) {
        super(props);
        this.state = {loanamttext:'Loan Amount', loantypetext:'Loan Type', sortbytext: 'Sort By', loanTypeCaptionStyle: loantypecaptionstyle, showloandata:false, vintagetext :'Age of Business', vintageCaptionStyle:loantypecaptionstyle, turnovertext:'Annual Turnover', turnoverCaptionStyle:loantypecaptionstyle, pincode:'' , dynamicContent:null , pincodeCaptionStyle:pincodecaptionstyle , pincodeInputStyle : pincodeinputstyle , selectedLoanAmount: null,loanAmountStyle1 : loanamtinputbox, loanAmountStyle2 : loanamtinputbox, loanAmountStyle3 : loanamtinputbox, loanAmountStyle4 : loanamtinputbox, loanAmountErrMsg : '', show : false, vendor_name:false , vendor_url:false, ifglid : false , username : 'Hi User', selectedOption: null, selectedOptionTurnover: null, pincodewarning: null, gstavailable: null, lenderOrder:'',maxLoan:500000,lenderLength:0, exploreLenderLength:0, showExplore:true, gst_status : 0,
        toggleExplore:true,isScrolledInDiv: false, url_selector:'Apply Now',showReject:false,showRejectReason:'',rejectLenName:'',anywayURL:'',lenderDataState:'',currLen:'', gstNo:'', gstStatus: ''};
        this.showExploreChange = this.showExploreChange.bind(this);  
        this.updateScrollPosition = this.updateScrollPosition.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showExplore !== this.state.showExplore) {
            this.props.onUpdate(this.state.showExplore);
        }
    }

    showExploreChange(){
        this.setState({showExplore:false});
    }

    recommend_engine = async(pageload, sortlogic='', gstavailable='', pincode = '',vintage='',turnover='',loan_amt='',gst_status='',load=0) => 
    {   
        var glid= getGlid();

        if(pageload)
        {
            if(vintage == "Age of Business")
            {
                vintage = undefined;
            }

            if(turnover == "Annual Turnover")
            {
                turnover = undefined;
            }

            if(loan_amt == "Loan Amount")
            {
                loan_amt = undefined;
            }

            if(turnover !== null && turnover !== "" && turnover !== undefined)
            {
                if(turnover_ranged_val.includes(turnover))
                {
                    turnover = turnover;
                }
                else
                {
                    if (turnover.includes('NA') || turnover.includes('No')) {
                        turnover = '25-40 Lakh';
                    } else if (turnover.includes('0 to 40 lakhs')) {
                        turnover = '10-25 Lakh';
                    } else if (turnover.includes('40 lakhs to 1.5 Cr.')) {
                        turnover = '40 Lakh-1.5 Cr';
                    } else if (turnover.includes('1.5 Cr. to 5 Cr.')) {
                        turnover = '1.5-5 Crore';
                    } else {
                        turnover = 'Above 5 Cr';
                    }
                }
                
            }

            if(turnover === "")
            {
                turnover = undefined;
            }


            pincode = pincode? pincode : "110001";
            vintage = vintage? (vintage_ranged_val.includes(vintage)? vintage_actual_val[vintage_ranged_val.indexOf(vintage)]: vintage) : "36"; 
            turnover = turnover? turnover_actual_val[turnover_ranged_val.indexOf(turnover)] : "50"; 
            loan_amt = loan_amt? (loanamt_ranged_val.includes(loan_amt)? loanamt_actual_val[loanamt_ranged_val.indexOf(loan_amt)]: loan_amt) : "5";
            gstavailable = gstavailable? gstavailable : 0;
            gst_status = gst_status? gst_status : 0;

        }
        else 
        {
            var pincode = this.state.pincode;

            var vintage = this.state.vintagetext;
    
            var turnover = this.state.turnovertext;
    
            var loan_amt = this.state.loanamttext; 

            var gstavailable= this.state.gstavailable;
            var gst_status= this.state.gst_status;
    
            if(vintage == "Age of Business")
            {
                vintage = undefined;
            }
    
            if(turnover == "Annual Turnover")
            {
                turnover = undefined;
            }
    
            if(loan_amt == "Loan Amount")
            {
                loan_amt = undefined;
            }
    
    
            pincode = pincode? pincode : "110001";
            vintage = vintage? vintage_actual_val[vintage_ranged_val.indexOf(vintage)] : "36"; 
            turnover = turnover? turnover_actual_val[turnover_ranged_val.indexOf(turnover)] : "50"; 
            loan_amt = loan_amt? loanamt_actual_val[loanamt_ranged_val.indexOf(loan_amt)] : "5";
            gstavailable = gstavailable? gstavailable : 0;
            gst_status = gst_status? gst_status : 0;
    
        }

        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var recommendURL = 'https://loans.indiamart.com/functions.php';
        }
        else{
            var recommendURL = 'https://dev-loans.indiamart.com/functions.php';
        }

        var recommendData = {
            "functionName" :"recommend_engine", 
            "pincode" :pincode,
            "vintage" :vintage,
            "turnover" :turnover, 
            "loan_amt" :loan_amt,
            "sort_logic" : "Best Approval",
            "glid" : glid,
            "is_msite" : 0,
            "gst_available": gstavailable,
            "gst_status":gst_status,
            "page_load":load
        };

        var formData = new FormData();
        for (var key in recommendData) {
            formData.append(key, recommendData[key]);
        }

            try{
                const response = await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    url: recommendURL,
                    data: formData,
                    timeout: 10000
                });

                var data_obj = response['data'];
                var lenderElements = Object.values(data_obj);
                if (localStorage.getItem("lenderOrder")) {
                    localStorage.removeItem("lenderOrder");
                }
                localStorage.setItem("lenderOrder", JSON.stringify(lenderElements));
                this.setState({lenderLength:lenderElements.length})
                this.setState({lenderDataState:lenderElements})
                // if(lender_data.pincode_unserviceable == undefined && lender_data.vintage == 1 && lender_data.turnover == 1)
                // {
                //     if(lender_data.sort_order == 1)
                //     {
                //         return offerTypeMap['recommended']
                //     }
                // }



                const variableMap = {
                    'indifi': "https://loans.imimg.com/images/loans/lenderimages/Indifi_logo-01.png",
                'icici': "https://loans.imimg.com/images/loans/lenderimages/ICICI_Bank_Logo-01.jpg",
                'iifl': "https://loans.imimg.com/images/loans/lenderimages/IIFL-New-Common-logo.png",
                'lkart': "https://loans.imimg.com/images/loans/lenderimages/lendingkartlogo-.png",
                'creditenable': "https://loans.imimg.com/images/loans/lenderimages/CreditEnable_Logo_Dark_Blue.png",
                'creditmantri': "https://loans.imimg.com/images/loans/lenderimages/CM-Logo-1920.png",
                'andromeda': "https://loans.imimg.com/images/loans/lenderimages/Andromeda.jpeg(2)(1).jpg",
                'psb': "https://loans.imimg.com/images/loans/lenderimages/PSB59-Logo-for-IndiaMart.png",
                'oneinfinity': oneinfinity,
                'loantap': "https://loans.imimg.com/images/loans/lenderimages/HQ_LoanTap_logo_.png",
                'poonawalla': poonawalla,
                'tatacapital': tataCapital, 
                'indialends' : indialends,
                'velocity':'https://loans.imimg.com/images/loans/lenderimages/velocity_logo.png',
                'finagg':'https://loans.imimg.com/images/loans/lenderimages/finagg_logo_new.png'
                }

                const velocitystyle= {padding:'10px 2px'}

                const offerTypeMap = {
                    'fastest': (<p
                    style={{borderRadius: '8px', background: '#A9E99F', padding: '2px 10px', height: '24px', width:'95px', color: '#58964E', fontWeight: '700', fontSize: '14px', marginTop: '5px', display:'flex', marginBottom:'5px'}}
                    > <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" style={{marginTop:'3px'}}>
                    <path d="M2.91594 4.04864C4.01571 3.16891 5.41071 2.64286 6.92857 2.64286C8.44643 2.64286 9.84143 3.16891 10.9412 4.04864L11.9794 3.01051L12.9895 4.02066L11.9514 5.05879C12.8311 6.15856 13.3571 7.55357 13.3571 9.07143C13.3571 12.6219 10.479 15.5 6.92857 15.5C3.37817 15.5 0.5 12.6219 0.5 9.07143C0.5 7.55357 1.02605 6.15856 1.90579 5.05879L0.867657 4.02066L1.87781 3.01051L2.91594 4.04864ZM6.92857 14.0714C9.69 14.0714 11.9286 11.8329 11.9286 9.07143C11.9286 6.31001 9.69 4.07143 6.92857 4.07143C4.16715 4.07143 1.92857 6.31001 1.92857 9.07143C1.92857 11.8329 4.16715 14.0714 6.92857 14.0714ZM7.64286 8.35714H9.78571L6.21429 13V9.78571H4.07143L7.64286 5.13943V8.35714ZM4.07143 0.5H9.78571V1.92857H4.07143V0.5Z" fill="#58964E"/>
                  </svg> &nbsp;Fastest
                    </p>),
                    'recommended': (<p
                        style={{borderRadius: '8px', background: '#FFEAB8', padding: '2px 10px', height: '24px', width:'150px', color: '#AB8B4B', fontWeight: '700', fontSize: '14px', marginTop: '5px', display:'flex', marginBottom:'5px'}} className='blink'
                        > 
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                        <path d="M10.9154 5.92273H15.6305C16.4443 5.92273 17.104 6.5824 17.104 7.39613V8.94648C17.104 9.13891 17.0663 9.3295 16.993 9.50749L14.7133 15.0438C14.5997 15.3199 14.3306 15.5 14.0321 15.5H1.63296C1.22608 15.5 0.89624 15.1702 0.89624 14.7633V7.39613C0.89624 6.98928 1.22608 6.65944 1.63296 6.65944H4.19808C4.43746 6.65944 4.66191 6.54314 4.79995 6.34757L8.81755 0.655967C8.92253 0.507248 9.12041 0.457513 9.28323 0.538924L10.6197 1.20714C11.3944 1.59453 11.7945 2.46944 11.5809 3.30888L10.9154 5.92273ZM5.31653 7.82895V14.0266H13.5387L15.6305 8.94648V7.39613H10.9154C9.95423 7.39613 9.25044 6.4907 9.48752 5.55922L10.153 2.94537C10.1957 2.77748 10.1157 2.6025 9.96071 2.52502L9.47367 2.28149L6.0037 7.19728C5.8196 7.45809 5.58426 7.67189 5.31653 7.82895ZM3.8431 8.13285H2.36967V14.0266H3.8431V8.13285Z" fill="#AB8B4B"/>
                      </svg>  */}
                      <img src={'https://loans.imimg.com/images/loans/chancemeter/checkCircle.png'} alt='check-circle' style={{width:'18px',height:'16px',marginRight:'5px',marginTop:'3px'}}/>
                      &nbsp;Recommended
                        </p>),
                    'bestfit': (<p
                        style={{borderRadius: '8px', background: '#E0D1FF', padding: '2px 10px', height: '24px', width:'100px', color: '#84799A', fontWeight: '700', fontSize: '14px', marginTop: '5px', display:'flex', marginBottom:'5px', whiteSpace:'nowrap'}}
                        > <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" style={{marginTop:'3px'}}>
                        <path d="M10.25 2.32812C11.2597 2.32812 12.0781 1.50964 12.0781 0.5H12.9219C12.9219 1.50964 13.7403 2.32812 14.75 2.32812V3.17188C13.7403 3.17188 12.9219 3.99036 12.9219 5H12.0781C12.0781 3.99036 11.2597 3.17188 10.25 3.17188V2.32812ZM0.5 7.25C2.98528 7.25 5 5.23528 5 2.75H6.5C6.5 5.23528 8.51472 7.25 11 7.25V8.75C8.51472 8.75 6.5 10.7647 6.5 13.25H5C5 10.7647 2.98528 8.75 0.5 8.75V7.25ZM3.40701 8C4.39038 8.5457 5.20432 9.3596 5.75 10.343C6.29568 9.3596 7.10962 8.5457 8.093 8C7.10962 7.4543 6.29568 6.6404 5.75 5.65701C5.20432 6.6404 4.39038 7.4543 3.40701 8ZM12.6875 9.5C12.6875 10.8462 11.5962 11.9375 10.25 11.9375V13.0625C11.5962 13.0625 12.6875 14.1538 12.6875 15.5H13.8125C13.8125 14.1538 14.9038 13.0625 16.25 13.0625V11.9375C14.9038 11.9375 13.8125 10.8462 13.8125 9.5H12.6875Z" fill="#84799A"/>
                      </svg> &nbsp;Best Fit
                        </p>),
                    'new':(<p className='blink'
                        style={{borderRadius: '8px', background: '#ffd7b5 ', padding: '2px 10px', height: '24px', width:'80px', color: '#AB8B4B', fontWeight: '700', fontSize: '14px', marginTop: '5px', display:'flex', marginBottom:'5px', whiteSpace:'nowrap'}}
                        > <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" style={{marginTop:'2px'}}>
                        <path d="M10.9154 5.92273H15.6305C16.4443 5.92273 17.104 6.5824 17.104 7.39613V8.94648C17.104 9.13891 17.0663 9.3295 16.993 9.50749L14.7133 15.0438C14.5997 15.3199 14.3306 15.5 14.0321 15.5H1.63296C1.22608 15.5 0.89624 15.1702 0.89624 14.7633V7.39613C0.89624 6.98928 1.22608 6.65944 1.63296 6.65944H4.19808C4.43746 6.65944 4.66191 6.54314 4.79995 6.34757L8.81755 0.655967C8.92253 0.507248 9.12041 0.457513 9.28323 0.538924L10.6197 1.20714C11.3944 1.59453 11.7945 2.46944 11.5809 3.30888L10.9154 5.92273ZM5.31653 7.82895V14.0266H13.5387L15.6305 8.94648V7.39613H10.9154C9.95423 7.39613 9.25044 6.4907 9.48752 5.55922L10.153 2.94537C10.1957 2.77748 10.1157 2.6025 9.96071 2.52502L9.47367 2.28149L6.0037 7.19728C5.8196 7.45809 5.58426 7.67189 5.31653 7.82895ZM3.8431 8.13285H2.36967V14.0266H3.8431V8.13285Z" fill="#AB8B4B"/>
                      </svg> &nbsp;New
                        </p>),
                        'overdraft':(<p className='blink'
                        style={{borderRadius: '8px', background: '#caf0f8', padding: '2px 10px', height: '24px', width:'100px',color:'#000000', fontWeight: '700', fontSize: '14px', marginTop: '5px', display:'flex', marginBottom:'5px', whiteSpace:'nowrap'}}
                        > <i style={{marginTop:'1px'}} class="bi bi-cash-stack"></i> &nbsp;Overdraft
                        </p>),

                    0 : (<p style={{paddingTop:'20px'}}> </p>),     

                    '-1' : (<p style={{paddingTop:'0px'}}> </p>),

                }

                const offerTypesvgMapHome = {
                    'new': (<div className="offer" style={{background: '#ffd7b5',display:'inline-flex',marginTop:'1px'}}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none" style={{marginRight:'5px'}}>
                        <path d="M10.9154 5.92273H15.6305C16.4443 5.92273 17.104 6.5824 17.104 7.39613V8.94648C17.104 9.13891 17.0663 9.3295 16.993 9.50749L14.7133 15.0438C14.5997 15.3199 14.3306 15.5 14.0321 15.5H1.63296C1.22608 15.5 0.89624 15.1702 0.89624 14.7633V7.39613C0.89624 6.98928 1.22608 6.65944 1.63296 6.65944H4.19808C4.43746 6.65944 4.66191 6.54314 4.79995 6.34757L8.81755 0.655967C8.92253 0.507248 9.12041 0.457513 9.28323 0.538924L10.6197 1.20714C11.3944 1.59453 11.7945 2.46944 11.5809 3.30888L10.9154 5.92273ZM5.31653 7.82895V14.0266H13.5387L15.6305 8.94648V7.39613H10.9154C9.95423 7.39613 9.25044 6.4907 9.48752 5.55922L10.153 2.94537C10.1957 2.77748 10.1157 2.6025 9.96071 2.52502L9.47367 2.28149L6.0037 7.19728C5.8196 7.45809 5.58426 7.67189 5.31653 7.82895ZM3.8431 8.13285H2.36967V14.0266H3.8431V8.13285Z" fill="#AB8B4B"/>
                        </svg><p style={{margin:'auto 0px'}}>Fast Digital Loans: Across Best Partners, Tailored for You.</p></div>),

                        'overdraft':<div className="offer" style={{background: '#caf0f8',display:'inline-flex',marginTop:'1px',color:'#000000',textTransform:'none'}}><i style={{marginTop:'1px',marginRight:'5px'}} class="bi bi-cash-stack"></i> 
                        Get Easy Overdraft Facility for your Business - Pay as you use.</div>,

                    'fastest': (<div className="offer" style={{background: '#A9E99F',display:'inline-flex',marginTop:'1px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" style={{marginRight:'5px'}}>
                    <path d="M2.71958 3.78522C3.89267 2.84683 5.38067 2.28571 6.99972 2.28571C8.61877 2.28571 10.1068 2.84683 11.2799 3.78522L12.3872 2.67788L13.4647 3.75538L12.3574 4.86271C13.2957 6.0358 13.8569 7.52381 13.8569 9.14286C13.8569 12.93 10.7868 16 6.99972 16C3.21263 16 0.142578 12.93 0.142578 9.14286C0.142578 7.52381 0.703698 6.0358 1.64208 4.86271L0.534746 3.75538L1.61224 2.67788L2.71958 3.78522ZM6.99972 14.4762C9.94524 14.4762 12.3331 12.0884 12.3331 9.14286C12.3331 6.19734 9.94524 3.80952 6.99972 3.80952C4.0542 3.80952 1.66639 6.19734 1.66639 9.14286C1.66639 12.0884 4.0542 14.4762 6.99972 14.4762ZM7.76163 8.38095H10.0473L6.23782 13.3333V9.90476H3.9521L7.76163 4.94872V8.38095ZM3.9521 0H10.0473V1.52381H3.9521V0Z" fill="#58964E"/>
                    </svg>    <p style={{margin:'auto 0px'}}> Fastest TAT at slightly higher rates</p></div>),

                    'fastest': (<div className="offer" style={{background: '#A9E99F',display:'inline-flex',marginTop:'1px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" style={{marginRight:'5px'}}>
                    <path d="M2.71958 3.78522C3.89267 2.84683 5.38067 2.28571 6.99972 2.28571C8.61877 2.28571 10.1068 2.84683 11.2799 3.78522L12.3872 2.67788L13.4647 3.75538L12.3574 4.86271C13.2957 6.0358 13.8569 7.52381 13.8569 9.14286C13.8569 12.93 10.7868 16 6.99972 16C3.21263 16 0.142578 12.93 0.142578 9.14286C0.142578 7.52381 0.703698 6.0358 1.64208 4.86271L0.534746 3.75538L1.61224 2.67788L2.71958 3.78522ZM6.99972 14.4762C9.94524 14.4762 12.3331 12.0884 12.3331 9.14286C12.3331 6.19734 9.94524 3.80952 6.99972 3.80952C4.0542 3.80952 1.66639 6.19734 1.66639 9.14286C1.66639 12.0884 4.0542 14.4762 6.99972 14.4762ZM7.76163 8.38095H10.0473L6.23782 13.3333V9.90476H3.9521L7.76163 4.94872V8.38095ZM3.9521 0H10.0473V1.52381H3.9521V0Z" fill="#58964E"/>
                    </svg>    <p style={{margin:'auto 0px'}}> Fastest TAT at slightly higher rates</p></div>),
                    'recommended': (<div className="col-12 offer" style={{backgroundColor:'#FFEAB8',marginTop:'1px',display:'inline-flex'}}>  
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                    <path d="M10.9154 5.92273H15.6305C16.4443 5.92273 17.104 6.5824 17.104 7.39613V8.94648C17.104 9.13891 17.0663 9.3295 16.993 9.50749L14.7133 15.0438C14.5997 15.3199 14.3306 15.5 14.0321 15.5H1.63296C1.22608 15.5 0.89624 15.1702 0.89624 14.7633V7.39613C0.89624 6.98928 1.22608 6.65944 1.63296 6.65944H4.19808C4.43746 6.65944 4.66191 6.54314 4.79995 6.34757L8.81755 0.655967C8.92253 0.507248 9.12041 0.457513 9.28323 0.538924L10.6197 1.20714C11.3944 1.59453 11.7945 2.46944 11.5809 3.30888L10.9154 5.92273ZM5.31653 7.82895V14.0266H13.5387L15.6305 8.94648V7.39613H10.9154C9.95423 7.39613 9.25044 6.4907 9.48752 5.55922L10.153 2.94537C10.1957 2.77748 10.1157 2.6025 9.96071 2.52502L9.47367 2.28149L6.0037 7.19728C5.8196 7.45809 5.58426 7.67189 5.31653 7.82895ZM3.8431 8.13285H2.36967V14.0266H3.8431V8.13285Z" fill="#AB8B4B"/>
                    </svg>     */}
                    <img src={'https://loans.imimg.com/images/loans/chancemeter/checkCircle.png'} alt='check-circle' style={{width:'16px',height:'16px',marginRight:'5px'}}/>
                    <p style={{margin:'auto 0px'}}>Highest chances of approval</p></div>),

                    'bestfit': (<div className="col-12 offer" style={{backgroundColor:'#E0D1FF',marginTop:'1px',display:'inline-flex'}}> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" style={{marginRight:'5px'}}>
                    <path d="M10.25 2.32812C11.2597 2.32812 12.0781 1.50964 12.0781 0.5H12.9219C12.9219 1.50964 13.7403 2.32812 14.75 2.32812V3.17188C13.7403 3.17188 12.9219 3.99036 12.9219 5H12.0781C12.0781 3.99036 11.2597 3.17188 10.25 3.17188V2.32812ZM0.5 7.25C2.98528 7.25 5 5.23528 5 2.75H6.5C6.5 5.23528 8.51472 7.25 11 7.25V8.75C8.51472 8.75 6.5 10.7647 6.5 13.25H5C5 10.7647 2.98528 8.75 0.5 8.75V7.25ZM3.40701 8C4.39038 8.5457 5.20432 9.3596 5.75 10.343C6.29568 9.3596 7.10962 8.5457 8.093 8C7.10962 7.4543 6.29568 6.6404 5.75 5.65701C5.20432 6.6404 4.39038 7.4543 3.40701 8ZM12.6875 9.5C12.6875 10.8462 11.5962 11.9375 10.25 11.9375V13.0625C11.5962 13.0625 12.6875 14.1538 12.6875 15.5H13.8125C13.8125 14.1538 14.9038 13.0625 16.25 13.0625V11.9375C14.9038 11.9375 13.8125 10.8462 13.8125 9.5H12.6875Z" fill="#84799A"/>
                    </svg>   Best Fit for your loan requirements</div>),
                    'pincodefail' : (<div className="offer" style={{paddingTop:'7px', textAlign:'center', minWidth:'30px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M8.49967 14.6654C12.1816 14.6654 15.1663 11.6806 15.1663 7.9987C15.1663 4.3168 12.1816 1.33203 8.49967 1.33203C4.81778 1.33203 1.83301 4.3168 1.83301 7.9987C1.83301 11.6806 4.81778 14.6654 8.49967 14.6654Z" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 6L6.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.5 6L10.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>   Does not provide loan in your area </div>),
                    'vintagefail' : (<div className="offer" style={{paddingTop:'7px', textAlign:'center', minWidth:'30px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M8.49967 14.6654C12.1816 14.6654 15.1663 11.6806 15.1663 7.9987C15.1663 4.3168 12.1816 1.33203 8.49967 1.33203C4.81778 1.33203 1.83301 4.3168 1.83301 7.9987C1.83301 11.6806 4.81778 14.6654 8.49967 14.6654Z" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 6L6.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.5 6L10.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> Your age of business is less than Lender's criterion </div>),
                    'turnoverfail' : (<div className="offer" style={{paddingTop:'7px', textAlign:'center', minWidth:'30px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M8.49967 14.6654C12.1816 14.6654 15.1663 11.6806 15.1663 7.9987C15.1663 4.3168 12.1816 1.33203 8.49967 1.33203C4.81778 1.33203 1.83301 4.3168 1.83301 7.9987C1.83301 11.6806 4.81778 14.6654 8.49967 14.6654Z" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 6L6.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.5 6L10.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> Your annual turnover is less than Lender's criterion </div>),
                    'gstfail' : (<div className="col-12 offer" style={{paddingTop:'7px',textAlign:'center', minWidth:'30px',marginTop:'-15px'}}> 
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M8.49967 14.6654C12.1816 14.6654 15.1663 11.6806 15.1663 7.9987C15.1663 4.3168 12.1816 1.33203 8.49967 1.33203C4.81778 1.33203 1.83301 4.3168 1.83301 7.9987C1.83301 11.6806 4.81778 14.6654 8.49967 14.6654Z" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 6L6.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.5 6L10.5 10" stroke="#CB5858" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>   */}
                    GST is mandatory to apply for loan with this partner</div>),
                    'iiflfooter' : (<div className="col-12 offer" style={{paddingTop:'7px',textAlign:'center', minWidth:'30px',marginTop:'-15px'}}> Preferred Partner for Sole Proprietorship Cases only</div>),
                    'rejected' : (<div className="col-12 offer" style={{paddingTop:'5px',textAlign:'center', minWidth:'30px',marginTop:'0px'}}> <i className="bi bi-x-circle-fill" style={{ color: '#787878', marginRight:'4px'}}></i> Application rejected by partner in last 45 days</div>),
                    'inprocess' : (<div className="col-12 offer" style={{paddingTop:'5px',textAlign:'center', minWidth:'30px',marginTop:'0px'}}><i className="bi bi-hourglass-split" style={{color: '#02A699', marginRight:'4px'}}></i> Application in progress - we recommend you to complete it</div>),

                };

                const conditionMet = {
                    'satisfied': (<i className="bi bi-check-circle" style={{color: '#02A699'}}></i>),
                    'unsatisfied': (<i className="bi bi-x-circle" style={{color: '#CB5858'}}></i>)
                }

                function getconditionicon(lender_data,feature)
                {
                    if(feature == 'pincode')
                    {
                        if(lender_data.pincode_unserviceable == undefined)
                        {
                            return conditionMet['satisfied'];
                        }
                        else
                        {
                            return conditionMet['unsatisfied'];
                        }
                    }
                    else if(feature == 'turnover')
                    {
                        if(lender_data.turnover)
                        {
                            return conditionMet['satisfied'];
                        }
                        else
                        {
                            return conditionMet['unsatisfied'];
                        }
                    }
                    else if(feature == 'vintage')
                    {
                        if(lender_data.vintage)
                        {
                            return conditionMet['satisfied'];
                        }
                        else
                        {
                            return conditionMet['unsatisfied'];
                        }
                    }
                }
  
                const { callerComp } = this.props;
                var jsxElements = '';
                var lenderorder = '';
                let i = 0;

                let ctr = 0;

                {lenderElements.map((lenderdata,  index) => {
                    if (lenderdata.offertype_footer !== "inprocess") {
                        ctr++;
                    }
                })}

                this.setState({exploreLenderLength:ctr});

                if (callerComp === "ExploreComponent") {
                let lenderPresent = 0;
                lenderPresent = lenderElements.filter(lenderdata => lenderdata.offertype_header === "recommended").length;
                if(lenderPresent === 0 && this.state.toggleExplore ){
                    this.showExploreChange();
                }

                this.setState({toggleExplore:false});

                    jsxElements = (
                        <div>
                        {lenderElements.map((lenderdata,  index) => {
                            if (lenderdata.offertype_footer !== "inprocess" && lenderdata.image != "indialends" && lenderdata.image != "lkart") {

                                return ( 
                            
                            lenderdata.offertype_header === 0 && (lenderdata.offertype_footer === '' || lenderdata.offertype_footer === undefined) ? (
                                <div style={{backgroundColor:'white'}}>
                                <div className="row tbody mainDivLender" style={{padding: '6px 0px'}} >
                                <div className="col-2 allTiles"> 
                                    <img src={variableMap[lenderdata.image]}  style={lenderdata.image === 'velocity' ? velocitystyle : null} alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
    
                                {   lenderdata.image != 'finagg' && 
                                        <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' && 
                                    <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>As Per Limit Utilised</div>
                                }
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center', whiteSpace:'nowrap',minWidth:'10px'}}><span >{lenderdata.amount}</span></div>
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center'}}><span id={'expRate'+index} >{lenderdata.intrate}</span></div>
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center'}}> <span >{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3" style={{paddingTop:'0px',paddingRight:'0px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {
                                (lenderdata.offertype_footer !== 'vintagefail' && lenderdata.offertype_footer !== 'turnoverfail' && lenderdata.offertype_footer !== 'pincodefail' && lenderdata.applynowbtn !== 'appBtnApplyRejected' && lenderdata.offertype_header !== 'bestfit' && lenderdata.offertype_header !== 'fastest' && lenderdata.offertype_header !== 'recommended') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_footer === 'vintagefail' || lenderdata.offertype_footer === 'turnoverfail' || lenderdata.offertype_footer == 'pincodefail' || lenderdata.applynowbtn === 'appBtnApplyRejected') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/low.png'} alt='low' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div style={{marginTop:'1px',fontWeight:'700',paddingTop:'3px'}}>Low</div>
                                </div>
                            }
    
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{textAlign:'center'}} onClick={lenderdata.applynowbtntxt =="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url], "Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
                            
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>
    
                                </div>
                            </div>
    
                            ):
                            lenderdata.offertype_header === 0 && (lenderdata.offertype_footer !== '' && lenderdata.offertype_footer !== undefined) ? (
                                <div className="row tbody mainDivLender" >
                                <div className="col-2 allTiles"> 
                                    <img src={variableMap[lenderdata.image]} style={lenderdata.image === 'velocity' ? velocitystyle : null} alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
                                {   lenderdata.image != 'finagg' && 
                                        <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' && 
                                    <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>As Per Limit Utilised</div>
                                }
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center', whiteSpace:'nowrap'}}><span>{lenderdata.amount} </span></div>
    
                                <div className="col-1 allTiles " style={{textAlign:'-webkit-center'}}><span id={'expRate'+index} >{lenderdata.intrate}</span></div>
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center'}}> <span >{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3 " style={{paddingTop:'0px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {
                                (lenderdata.offertype_footer !== 'vintagefail' && lenderdata.offertype_footer !== 'turnoverfail' && lenderdata.offertype_footer !== 'pincodefail' && lenderdata.applynowbtn !== 'appBtnApplyRejected' && lenderdata.offertype_header !== 'bestfit' && lenderdata.offertype_header !== 'fastest' && lenderdata.offertype_header !== 'recommended') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_footer === 'vintagefail' || lenderdata.offertype_footer === 'turnoverfail' || lenderdata.offertype_footer == 'pincodefail' || lenderdata.applynowbtn === 'appBtnApplyRejected') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/low.png'} alt='low' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',fontWeight:'700',paddingTop:'3px'}}>Low</div>
                                </div>
                            }
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{ textAlign:'center',marginTop:'33px'}} onClick={lenderdata.applynowbtntxt =="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url], "Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
    
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>
                                </div>
                            ):(
    
                                <div className="row tbody mainDivLender">
                                <div className="col-2"> {offerTypeMap[lenderdata.offertype_footer]}
                                    <img src={variableMap[lenderdata.image]} style={lenderdata.image === 'velocity' ? velocitystyle : null} alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
                                
                                {
                                    lenderdata.image != 'finagg' &&
                                    <div className='col-1' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'50px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' &&
                                    <div className='col-1' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'50px'}}>As Per Limit Utilised</div>
                                }

                                <div className="col-1 " style={{textAlign:'-webkit-center', whiteSpace:'nowrap'}}><span style={{paddingTop:'50px'}}>{lenderdata.amount} </span></div>
    
                                <div className="col-1" style={{textAlign:'-webkit-center'}}><span id={'expRate'+index} style={{paddingTop:'50px'}}>{lenderdata.intrate}</span></div>
    
                                <div className="col-1" style={{textAlign:'-webkit-center'}}> <span style={{paddingTop:'50px'}}>{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3" style={{paddingTop:'20px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {   
                                lenderdata.offertype_header == 'recommended' &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/high.png'} alt='high' style={{height:'50px',width:'80px',marginTop:'20px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#2AA854',fontWeight:'700',paddingTop:'3px'}}>High</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_header == 'bestfit' || lenderdata.offertype_header == 'fastest') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/good.png'} alt='good' style={{height:'50px',width:'80px',marginTop:'20px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#FFD700',fontWeight:'700',paddingTop:'3px'}}>Good</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_header === 'overdraft') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
    
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{ textAlign:'center',marginTop:'33px'}} onClick={lenderdata.applynowbtntxt =="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url], "Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>  
                                </div>
                            )
                                
                        )}})}
                        </div>
                    );
                } 
                else {
                    jsxElements = (
                        <div>
                        {lenderElements.map((lenderdata,  index) => {
                            
                           if(lenderdata.image == "indialends" || lenderdata.image == "lkart"){
                            return null;
                           }
                            return ( 
    
                            lenderdata.offertype_header === 0 && (lenderdata.offertype_footer === '' || lenderdata.offertype_footer === undefined) ? (
                                <div style={{backgroundColor:'white'}}>
                                <div className="row tbody mainDivLender" style={{padding: '6px 0px'}} >
                                <div className="col-2 allTiles"> 
                                    <img src={variableMap[lenderdata.image]} style={lenderdata.image === 'velocity' ? velocitystyle : null}  alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
    
                                {   lenderdata.image != 'finagg' && 
                                        <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' && 
                                    <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>As Per Limit Utilised</div>
                                }
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center', whiteSpace:'nowrap',minWidth:'10px'}}><span >{lenderdata.amount}</span></div>
    
                                <div className="col-1 allTiles" id={'intRate'+index} style={{textAlign:'-webkit-center'}}><span id={'intRate'+index} >{lenderdata.intrate}</span></div>
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center'}}> <span >{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3" style={{paddingTop:'0px',paddingRight:'0px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {
                                (lenderdata.offertype_footer !== 'vintagefail' && lenderdata.offertype_footer !== 'turnoverfail' && lenderdata.offertype_footer !== 'pincodefail' && lenderdata.applynowbtn !== 'appBtnApplyRejected' && lenderdata.offertype_header !== 'bestfit' && lenderdata.offertype_header !== 'fastest' && lenderdata.offertype_header !== 'recommended') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_footer === 'vintagefail' || lenderdata.offertype_footer === 'turnoverfail' || lenderdata.offertype_footer == 'pincodefail' || lenderdata.applynowbtn === 'appBtnApplyRejected') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/low.png'} alt='low' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div style={{marginTop:'1px',fontWeight:'700',paddingTop:'3px'}}>Low</div>
                                </div>
                            }
    
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{textAlign:'center'}} onClick={lenderdata.applynowbtntxt=="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url],"Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
                            
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>
    
                                </div>
                            </div>
    
                            ):
                            lenderdata.offertype_header === 0 && (lenderdata.offertype_footer !== '' && lenderdata.offertype_footer !== undefined) ? (
                                <div className="row tbody mainDivLender" >
                                <div className="col-2 allTiles"> 
                                    <img src={variableMap[lenderdata.image]} style={lenderdata.image === 'velocity' ? velocitystyle : null} alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
                                {   lenderdata.image != 'finagg' && 
                                        <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' && 
                                    <div className='col-1 allTiles' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'4px'}}>As Per Limit Utilised</div>
                                }
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center', whiteSpace:'nowrap'}}><span>{lenderdata.amount} </span></div>
    
                                <div className="col-1 allTiles " id={'intRate'+index} style={{textAlign:'-webkit-center'}}><span id={'intRate'+index} >{lenderdata.intrate}</span></div>
    
                                <div className="col-1 allTiles" style={{textAlign:'-webkit-center'}}> <span >{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3 " style={{paddingTop:'0px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {
                                (lenderdata.offertype_footer !== 'vintagefail' && lenderdata.offertype_footer !== 'turnoverfail' && lenderdata.offertype_footer !== 'pincodefail' && lenderdata.applynowbtn !== 'appBtnApplyRejected' && lenderdata.offertype_header !== 'bestfit' && lenderdata.offertype_header !== 'fastest' && lenderdata.offertype_header !== 'recommended') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_footer === 'vintagefail' || lenderdata.offertype_footer === 'turnoverfail' || lenderdata.offertype_footer == 'pincodefail' || lenderdata.applynowbtn === 'appBtnApplyRejected') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/low.png'} alt='low' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',fontWeight:'700',paddingTop:'3px'}}>Low</div>
                                </div>
                            }
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{ textAlign:'center',marginTop:'33px'}} onClick={lenderdata.applynowbtntxt=="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url],"Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
    
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>
                                </div>
                            ):(
    
                                <div className="row tbody mainDivLender">
                                <div className="col-2"> {offerTypeMap[lenderdata.offertype_footer]}
                                    <img src={variableMap[lenderdata.image]} style={lenderdata.image === 'velocity' ? velocitystyle : null} alt="Logo" className="img-fluid img-thumbnail img-responsive"/>
                                </div>
                                {
                                    lenderdata.image != 'finagg' &&
                                    <div className='col-1' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'50px'}}>{this.emiCalculator(this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan,lenderdata.intrate.replace('%', ''),3)}</div>
                                }

                                {
                                    lenderdata.image == 'finagg' &&
                                    <div className='col-1' id={'emiStart'+index} style={{textAlign:'-webkit-center', whiteSpace:'nowrap',paddingTop:'50px'}}>As Per Limit Utilised</div>
                                }
                                
                                <div className="col-1 " style={{textAlign:'-webkit-center', whiteSpace:'nowrap'}}><span style={{paddingTop:'50px'}}>{lenderdata.amount} </span></div>
    
                                <div className="col-1" style={{textAlign:'-webkit-center'}}><span id={'intRate'+index} style={{paddingTop:'50px'}}>{lenderdata.intrate}</span></div>
    
                                <div className="col-1" style={{textAlign:'-webkit-center'}}> <span style={{paddingTop:'50px'}}>{lenderdata.processingfees}</span></div>
                                {/* <div className="col-3" style={{paddingTop:'20px'}}>
                                    <ul className="list-unstyled" style={{marginLeft:'20px'}}>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'pincode')} &nbsp;Pincode</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'turnover')} &nbsp;Business Turnover</li>
                                    <li style={{whiteSpace:'nowrap'}}>&nbsp;&nbsp; {getconditionicon(lenderdata,'vintage')} &nbsp;Age of Business</li>
                                    </ul>
                                </div> */}
                            {   
                                lenderdata.offertype_header == 'recommended' &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/high.png'} alt='high' style={{height:'50px',width:'80px',marginTop:'20px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#2AA854',fontWeight:'700',paddingTop:'3px'}}>High</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_header == 'bestfit' || lenderdata.offertype_header == 'fastest') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div><img src={'https://loans.imimg.com/images/loans/chancemeter/good.png'} alt='good' style={{height:'50px',width:'80px',marginTop:'20px'}}/></div>
                                    <div style={{marginTop:'1px',color:'#FFD700',fontWeight:'700',paddingTop:'3px'}}>Good</div>
                                </div>
                            }
                            {
                                (lenderdata.offertype_header === 'overdraft') &&
                                <div className='col-2' style={{textAlign:'center'}}>
                                    <div ><img src={'https://loans.imimg.com/images/loans/chancemeter/fair.png'} alt='fair' style={{height:'50px',width:'80px',marginTop:'15px'}}/></div>
                                    <div  style={{marginTop:'1px',color:'#F49F08',fontWeight:'700',paddingTop:'3px'}}>Medium</div>
                                </div>
                            }
    
                                    <div className="col-2" style={{border: 'none', paddingTop:'0px'}}>
                                        <div className={lenderdata.applynowbtn} style={{ textAlign:'center',marginTop:'33px'}} onClick={lenderdata.applynowbtntxt=="Apply Now"?() => this.logincheck([lenderdata.logincheck], [lenderdata.redirect_url],"Apply Now",lenderdata):() => this.logincheck([lenderdata.logincheck], [lenderdata.applications_url],"Continue",lenderdata)}>{lenderdata.applynowbtntxt}</div>
                                    </div>
    
                                {offerTypesvgMapHome[lenderdata.offertype_footer]}
                                <div style={{display:'none'}}>{lenderorder += (i++) + ': ' + lenderdata.image + ' '}</div>  
                                </div>
                            )
                                
                        )})}
                        </div>
                    );
                }

                this.setState({ dynamicContent: jsxElements, lenderOrder:lenderorder});
                if(pageload)
                {
                    var host = window.location.hostname;
                    var fullUrl = window.location.href;
                    if(callerComp != "ExploreComponent" && fullUrl == "https://loans.indiamart.com/applications"){
                        fullUrl = window.location.origin + "/";
                    }   
                    
                    if(host == 'loans.indiamart.com'){
                        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
                    }
                    else{
                        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
                    }

                    var getlenderorderData = {
                        "glid" : glid,
                        "functionName" : "StoreLenderOrderData",
                        "lenderorder" : lenderorder,
                        "action" : "Landing",
                        "ApplyNow" : "NA",
                        "device" : "Desktop",
                        "fullUrl": fullUrl, 
                    };

                    var formData = new FormData();
                    for (var key in getlenderorderData) {
                        formData.append(key, getlenderorderData[key]);
                    }

                    var self = this;

                    await axios({
                        method: 'post',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        url: getlenderorderURL,
                        data: formData,
                        timeout: 10000
                    });
                    }
            }
            catch (error) {
                console.error('Error fetching GST data:', error);
            }
            
    }

    // loanAmtDivClick = (event) => {
    //     event.stopPropagation();
    //     var dropdownMenuButton1 = document.getElementById('dropdownMenuButton1');
    //     dropdownMenuButton1.click();
    // }
    // loantypeDivClick = (event) => {
    //     event.stopPropagation();
    //     var dropdownMenuButton2 = document.getElementById('dropdownMenuButton2');
    //     dropdownMenuButton2.click();
    // }
    vintageDivClick = (event) => {
        event.stopPropagation();
        var dropdownMenuButton2 = document.getElementById('dropdownMenuButton4');
        dropdownMenuButton2.click();
    }
    turnoverDivClick = (event) => {
        event.stopPropagation();
        var dropdownMenuButton2 = document.getElementById('dropdownMenuButton5');
        dropdownMenuButton2.click();
    }

    sorttypedivclick = (event) => {
        event.stopPropagation();
        var dropdownMenuButton3 = document.getElementById('dropdownMenuButton6');
        dropdownMenuButton3.click();
    }

    // handleRadioClick = (buttontype) => {
    //     const updatedStyle = {
    //         color: 'var(--Grey-3, #757575)',
    //         fontFamily: 'Arial',
    //         fontSize: '12px',
    //         fontStyle: 'normal',
    //         fontWeight: '400',
    //         lineHeight: 'normal',
    //         textTransform: 'capitalize',
    //         marginLeft: '0px',
    //         marginTop: '-9px',
    //         marginBottom: '3px',
    //     };
    
    //     this.setState({
    //         loanamttext: buttontype,
    //         buttonTextColor: 'black',
    //         loanAmountCaptionStyle: updatedStyle
    //     });
    // }
    
    handleRadioClick1 = (buttontype) => {
        const updatedStyle = {
            color: 'var(--Grey-3, #757575)',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginLeft: '0px',
            marginTop: '-9px',
            marginBottom: '3px',
        };
        this.setState({ buttonTextColor1: 'black', loantypetext:buttontype, loanTypeCaptionStyle:updatedStyle });
    }

    handleRadioClickVintage = (buttontype) => {
        const updatedStyle = {
            color: 'var(--Grey-3, #757575)',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginLeft: '0px',
            marginTop: '-9px',
            marginBottom: '3px',
        };
    
        this.setState({
            vintagetext: buttontype,
            buttonTextColor4: 'black',
            vintageCaptionStyle: updatedStyle,
            selectedOption: buttontype
        });
    }

    handleRadioClickTurnover = (buttontype) => {
        const updatedStyle = {
            color: 'var(--Grey-3, #757575)',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginLeft: '0px',
            marginTop: '-9px',
            marginBottom: '3px',
        };
    
        this.setState({
            turnovertext: buttontype,
            buttonTextColor3: 'black',
            turnoverCaptionStyle: updatedStyle,
            selectedOptionTurnover: buttontype
        });

        const updatedloanamtinputbox = {
            display: 'flex',
            padding: '14px 30px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '6px',
            border: 'none',
            background:'#30318C',
            color:'#FFF',
            width:'140px',
            height: '50px'
        }

        const retractedloanamtinputbox = {
            display: 'flex',
            padding: '14px 30px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '6px',
            border: 'none',
            width:'140px',
            height: '50px'
        }
        this.setState({loanAmountErrMsg : ''}); // set to default
        if(buttontype == 'Upto 10 Lakh') {
            this.setState({ loanamttext: 'Upto 5 Lakh', loanAmountStyle1:updatedloanamtinputbox, loanAmountStyle2 : retractedloanamtinputbox,  loanAmountStyle3: retractedloanamtinputbox, loanAmountStyle4:retractedloanamtinputbox   }, function() {
                this.recommend_engine(0);
            });
        } else if(buttontype == '10-25 Lakh' && this.state.loanamttext != 'Upto 5 Lakh') {
            this.setState({ loanamttext: '5-10 Lakh', loanAmountStyle1:retractedloanamtinputbox, loanAmountStyle2 : updatedloanamtinputbox,  loanAmountStyle3: retractedloanamtinputbox, loanAmountStyle4:retractedloanamtinputbox   }, function() {
                this.recommend_engine(0);
            });
        } 
    }

    prefillTurnover = (buttontype) => {
        const updatedStyle = {
            color: 'var(--Grey-3, #757575)',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginLeft: '0px',
            marginTop: '-9px',
            marginBottom: '3px',
        };
        this.setState({
            turnovertext: buttontype,
            buttonTextColor3: 'black',
            turnoverCaptionStyle: updatedStyle,
            selectedOptionTurnover: buttontype
        });
    }



    showLoandataModal = () => {
        this.setState({ showloandata: true });
    }

    handleClose = () => {
        this.setState({ showloandata: false });
    }

    selectloanamount = (buttontext,btn_no,pageload=null) => {

        const updatedloanamtinputbox = {
            display: 'flex',
            padding: '14px 30px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '6px',
            border: 'none',
            background:'#30318C',
            color:'#FFF',
            width:'140px',
            height: '50px'
        }

        const retractedloanamtinputbox = {
            display: 'flex',
            padding: '14px 30px',
            alignItems: 'flex-start',
            gap: '10px',
            borderRadius: '6px',
            border: 'none',
            width:'140px',
            height: '50px'
        }
        this.setState({loanAmountErrMsg : ''}); // set to default
        if(this.state.turnovertext == 'Upto 10 Lakh' && btn_no != 1) {
            this.setState({loanAmountErrMsg : 'Loan Amount cant be greater than 50% of Annual Turnover'});
        } else if (this.state.turnovertext == '10-25 Lakh' && btn_no > 2){
            this.setState({loanAmountErrMsg : 'Loan Amount cant be greater than 50% of Annual Turnover'});
        }
        else if(this.state.turnovertext == '25-40 Lakh' && btn_no == 4)
        {
            this.setState({loanAmountErrMsg : 'Loan Amount cant be greater than 50% of Annual Turnover'});
        }
        else if(btn_no == 1)
        {
        
            this.setState({ loanamttext: buttontext, loanAmountStyle1:updatedloanamtinputbox, loanAmountStyle2 : retractedloanamtinputbox,  loanAmountStyle3: retractedloanamtinputbox, loanAmountStyle4:retractedloanamtinputbox   }, function() {
                if(!pageload)
                {
                    this.recommend_engine(0);
                }
            });
            
        }
        else if(btn_no == 2)
        {
            this.setState({ loanamttext: buttontext, loanAmountStyle2:updatedloanamtinputbox, loanAmountStyle1 : retractedloanamtinputbox,  loanAmountStyle3: retractedloanamtinputbox, loanAmountStyle4:retractedloanamtinputbox   }, function() {
                this.recommend_engine(0);
            });
        }
        else if(btn_no == 3)
        {
            this.setState({ loanamttext: buttontext, loanAmountStyle3:updatedloanamtinputbox, loanAmountStyle2 : retractedloanamtinputbox,  loanAmountStyle1: retractedloanamtinputbox, loanAmountStyle4:retractedloanamtinputbox   }, function() {
                this.recommend_engine(0);
            });
        }
        else if(btn_no == 4)
        {
            this.setState({ loanamttext: buttontext, loanAmountStyle4:updatedloanamtinputbox, loanAmountStyle2 : retractedloanamtinputbox,  loanAmountStyle3: retractedloanamtinputbox, loanAmountStyle1:retractedloanamtinputbox   }, function() {
                this.recommend_engine(0);
            });
        }

        

    }

    async componentDidMount() {
        const cookie = new Cookies();
        let hostName = window.location.hostname;
        // if( hostName == 'localhost'){
            // cookie.set('ImeshVisitor',"fn=Komal|em=d*******@gmail.com|phcc=91|iso=IN|mb1=8118804003|ctid=73648|glid=13035342|cd=03/MAY/2023|cmid=10|utyp=P|ev=V|uv=V|usts=|admln=0|admsales=0");
        // }
        window.addEventListener('scroll', this.updateScrollPosition);
        var visitor_name = '', glid = '';

        let visitorData = cookie.get('ImeshVisitor');
        if (visitorData) {
            visitorData = visitorData.split("|");

            let sd = [];
            visitorData.map((data) => {
                sd = data.split("=")
                if (sd[0] == "fn") {
                    visitor_name = sd[1]
                }
                if (sd[0] == "glid") {
                    glid = sd[1]
                }
            })
        }

        if(glid && glid != ''){
            this.gstapifunction(glid);
        }else{
            this.recommend_engine(0);
        }


        if (visitor_name) {
            this.setState({username : 'Hi ' + visitor_name});
        }

        this.selectloanamount('Upto 5 Lakh',1,1);

        // await this.recommend_engine();

    }


    handlePincodeChange = (event) => {
        const updatedStyle = {
            color: 'var(--Grey-3, #757575)',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            marginLeft: '10px',
            marginTop:'-25px',
            marginBottom:'3px'
        };

        const updatedpincodestyle = {
            width: '100%', 
            fontFamily: 'Arial', 
            fontSize: '14px', 
            color: '#000', 
            backgroundColor:'inherit', 
            marginLeft:'-55px', 
            marginTop:'10px', 
            textAlign:'left'
        }

        if(event.target.value.length != 6)
        {
            this.setState({
                pincode: event.target.value,
                // buttonTextColor3: 'black',
                pincodeCaptionStyle: updatedStyle,
                pincodeInputStyle: updatedpincodestyle,
                pincodewarning : 'Please enter correct 6 digit Pincode'
            });
        }
        else
        {
            this.setState({
                pincode: event.target.value,
                // buttonTextColor3: 'black',
                pincodeCaptionStyle: updatedStyle,
                pincodeInputStyle: updatedpincodestyle,
                pincodewarning : null
            });
        }
    

    }

    gstapifunction = async (glid='') => {

        var host = window.location.hostname;
        var self = this;
        try{
    
            var data_obj = await getGstData(glid);
            var gst_available = '', gst_status=0, gstNo = '', gstStatus = '';
    
            if (data_obj['code'] == '200') {
                if (data_obj['gstnum'] != '' && data_obj['gstnum'] != null) {
                    self.setState({ gstavailable: data_obj['gstnum'] });
                    gst_available = data_obj['gstnum'];
                }
                if (data_obj['data']['values']) {

                    if(data_obj['data']['values']['gstin_number']);
                    {
                        gstNo = data_obj['data']['values']['gstin_number'];
                        this.setState({gstNo:gstNo});
                    }

                    if(data_obj['data']['values']['gstin_status']);
                    {
                        gstStatus = data_obj['data']['values']['gstin_status'];
                        if(gstStatus == 'Active'){
                            gst_status = 1;
                            this.setState({gst_status:1})
                            localStorage.setItem("showVeloBanner","1");
                        }
                        else{
                            localStorage.setItem("showVeloBanner","0");
                        }
                        this.setState({gstStatus:gstStatus});
                    }
    
                    if (data_obj['data']['values']['pincode']) {
                        var pincode = data_obj['data']['values']['pincode'];
                        self.setState({ pincode: pincode });
    
                        const updatedStyle = {
                            color: 'var(--Grey-3, #757575)',
                            fontFamily: 'Arial',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            marginLeft: '10px',
                            marginTop: '-25px',
                            marginBottom: '3px'
                        };
    
                        const updatedpincodestyle = {
                            width: '100%',
                            fontFamily: 'Arial',
                            fontSize: '14px',
                            color: '#000',
                            backgroundColor: 'inherit',
                            marginLeft: '-55px',
                            marginTop: '10px',
                            textAlign: 'left'
                        }
    
                        self.setState({
                            pincodeCaptionStyle: updatedStyle,
                            pincodeInputStyle: updatedpincodestyle
                        });
                    }

                    
                    if (data_obj['data']['values']['registration_date']) {

                        var registrationDate = data_obj['data']['values']['registration_date'];
                        const [day, month, year] = registrationDate.split('-');

                        if (day && month && year) {
                            const givenDate = new Date(year, month - 1, day);
                            const currentDate = new Date();
                            const yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
                            var gstvintage = yearsDifference * 12 + (currentDate.getMonth() - givenDate.getMonth());

                            // gstvintage = 23
                            localStorage.setItem("vin",gstvintage);
                        }

                        if (gstvintage > 0 && gstvintage <= 12) {
                            self.handleRadioClickVintage('Upto 1 Year')
                            gstvintage = 12
                        }
                        else if (gstvintage > 12 && gstvintage <= 36) {
                            if(gstvintage > 12 && gstvintage <= 23){
                                gstvintage = 23     //using for finagg
                            }
                            else{
                                gstvintage = 36
                            }
                            self.handleRadioClickVintage('1-3 Years')
                        }
                        else if (gstvintage > 36) {
                            self.handleRadioClickVintage('Above 3 Years')
                            gstvintage = 40
                        }
                        else {
                            self.handleRadioClickVintage('Above 3 Years')
                            gstvintage = 40
                        }
                    }
    
                    var annual_turnover_slab = data_obj['data']['values']['annual_turnover_slab'];
                    if (annual_turnover_slab.includes('NA') || annual_turnover_slab.includes('No') || annual_turnover_slab == "") {
                        self.prefillTurnover('25-40 Lakh')
                        localStorage.setItem("turnover","0");
                    }
                    else if (annual_turnover_slab.includes('0 to 40 lakhs')) {
                        self.prefillTurnover('10-25 Lakh')
                        self.setState({ maxLoan: 1000000 });
                        localStorage.setItem("turnover","0");
                    }
                    else if (annual_turnover_slab.includes('40 lakhs to 1.5 Cr.')) {
                        self.prefillTurnover('40 Lakh-1.5 Cr')
                        self.setState({ maxLoan: 2000000 });
                        localStorage.setItem("turnover","1");
                    }
                    else if (annual_turnover_slab.includes('1.5 Cr. to 5 Cr.')) {
                        self.prefillTurnover('1.5-5 Crore')
                        self.setState({ maxLoan: 2000000 });
                        localStorage.setItem("turnover","1");
                    }
                    else {
                        self.prefillTurnover('Above 5 Cr')
                        self.setState({ maxLoan: 2000000 });
                        localStorage.setItem("turnover","1");
                    }
    
                }
                await self.recommend_engine(1, 'Best Approval', gst_available, pincode, gstvintage, annual_turnover_slab, 5,gst_status,1);
            }
            else {
                localStorage.setItem("vin","0");
                localStorage.setItem("turnover","0");
                localStorage.setItem("showVeloBanner","0");
                var funcURL = host == 'loans.indiamart.com' ? "https://loans.indiamart.com/functions.php" : "https://dev-loans.indiamart.com/functions.php";
    
                var pinData = {
                    "glid": glid,
                    "functionName": "getpincode"
                };
    
                var pinFormData = new FormData();
                for (var key in pinData) {
                    pinFormData.append(key, pinData[key]);
                }
    
                const pinResponse = await axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    url: funcURL,
                    data: pinFormData,
                    timeout: 10000
                });
    
                if (pinResponse['data']['output']['pincode']) {
                    var pincode = pinResponse.data.output.pincode;
                    self.setState({ pincode: pincode });
                }
                await self.recommend_engine(1, 'Best Approval', '', pincode, '', '', 5,gst_status,1);
            }
        } catch (error) {
            console.error("An error occurred during gstapifunction execution", error);
            this.recommend_engine(1);
        }
    }  

    logincheck = async (vendor_name, vendor_url, url_selector,lenderData='') =>
    {   
        var glid= getGlid();
        this.setState({vendor_name:vendor_name});
        this.setState({vendor_url:vendor_url});
        var self =  this;
        if(!glid)
        {
            this.setState({show:true});
            this.setState({currLen:lenderData})
            this.setState({url_selector:url_selector});
            gatrack('Non Logged in User','Popup Opened','Click');
        }
        else
        {      
                
            if(lenderData.image == 'oneinfinity'){
                var gstNo = this.state.gstNo;
                var gstStatus = this.state.gstStatus; 
            }

            if(lenderData['offertype_footer'] !== undefined && lenderData['offertype_footer'] !== null){
                var rejectReason = lenderData['offertype_footer'];
            }
            if((rejectReason === 'vintagefail' || rejectReason === 'turnoverfail' || rejectReason === 'pincodefail' || rejectReason === 'rejected' || ((gstStatus !== 'Active' || (gstNo != '' && gstNo.charAt(5) !== 'P')) && lenderData.image == 'oneinfinity')) && (lenderData['applynowbtn'] == 'appBtnApply' ||  lenderData['applynowbtn'] == 'appBtnApplyRejected')){

                this.setState({showReject:true});                   
                gatrack('Reject Popup',vendor_name,'Open');
                if(rejectReason === 'pincodefail'){        //1st
                    this.setState({showRejectReason:' does not service loan applications in your area.'});
                }
                else if(rejectReason === 'vintagefail'){         //2nd
                    this.setState({showRejectReason:'does not service loan applications in your age of business bracket.'});
                }
                else if(rejectReason === 'turnoverfail'){       //3rd
                    this.setState({showRejectReason:'does not service loan applications in your turnover range.'});
                }
                else if(lenderData.image == 'oneinfinity' && gstNo && gstNo.charAt(5) !== 'P'){       //4th
                    self.setState({showReject:true});
                    self.setState({showRejectReason:'serves only Proprietorship Companies.'});
                    self.setState({rejectLenName:'OneInfinity'})
                }
                else if(lenderData.image == 'oneinfinity' && gstStatus !== 'Active'){        //5th
                    self.setState({showReject:true});
                    self.setState({showRejectReason:'serves only customers with an Active GST.'});
                    self.setState({rejectLenName:'OneInfinity'})
                }
                else if(rejectReason === 'rejected'){       //6th
                    this.setState({showRejectReason:'recently rejected your loan application.'});
                }
                
                let lenderName = vendor_name.toString();
                lenderName = lenderName.replace("Business Loan", "").trim();
                
                this.setState({rejectLenName : lenderName});
            }
            else{
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" ||  vendor_name == "Indifi Business Loan" || vendor_name == 'IIFL Business Loan')
                {
                    glidtracker(vendor_name, url_selector);
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity" || vendor_name == "IndiaLends" || vendor_name == "Velocity" || vendor_name == "FinAgg")
                {
                    geturldata(vendor_name, url_selector);
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    clicktracking(product_name,self);                              
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                } 
            }
        }
        if(!sessionStorage.getItem('LoansHelp')){
            sessionStorage.setItem('LoansHelp', 'true');
        }
    }

    checkRejected = async (vendor_name, vendor_url, url_selector,lenderData='') => {
        var lenderDataCurr = this.state.currLen;
        vendor_name = lenderDataCurr['logincheck'];
        vendor_url = lenderDataCurr['redirect_url'];

        for(var i = 0 ; i < lenderData.length ; i++){
            if(lenderDataCurr['image'] == lenderData[i]['image']){
                lenderData = lenderData[i];
            }
        }
        
        var self =  this;
    
        if(lenderData.image == 'oneinfinity'){
            var gstNo = this.state.gstNo;
            var gstStatus = this.state.gstStatus;
        }

        if(lenderData['offertype_footer'] !== undefined && lenderData['offertype_footer'] !== null){
            var rejectReason = lenderData['offertype_footer'];
        }
        if((rejectReason === 'vintagefail' || rejectReason === 'turnoverfail' || rejectReason === 'pincodefail' || rejectReason === 'rejected' || ((gstStatus !== 'Active' || (gstNo != '' && gstNo.charAt(5) !== 'P')) && lenderData.image == 'oneinfinity')) && (lenderData['applynowbtn'] == 'appBtnApply' ||  lenderData['applynowbtn'] == 'appBtnApplyRejected')){
            this.setState({showReject:true});               
            gatrack('Reject Popup',vendor_name,'Open');

            if(rejectReason === 'pincodefail'){        //1st
                this.setState({showRejectReason:' does not service loan applications in your area.'});
            }
            else if(rejectReason === 'vintagefail'){         //2nd
                this.setState({showRejectReason:'does not service loan applications in your age of business bracket.'});
            }
            else if(rejectReason === 'turnoverfail'){       //3rd
                this.setState({showRejectReason:'does not service loan applications in your turnover range.'});
            }
            else if(lenderData.image == 'oneinfinity' && gstNo && gstNo.charAt(5) !== 'P'){       //4th
                self.setState({showReject:true});
                self.setState({showRejectReason:'serves only Proprietorship Companies.'});
                self.setState({rejectLenName:'OneInfinity'})
            }
            else if(lenderData.image == 'oneinfinity' && gstStatus !== 'Active'){        //5th
                self.setState({showReject:true});
                self.setState({showRejectReason:'serves only customers with an Active GST.'});
                self.setState({rejectLenName:'OneInfinity'})
            }
            else if(rejectReason === 'rejected'){       //6th
                this.setState({showRejectReason:'recently rejected your loan application.'});
            }
            
            let lenderName = vendor_name.toString();
            lenderName = lenderName.replace("Business Loan", "").trim();
            
            this.setState({rejectLenName : lenderName});
        }
        else{
            if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" ||  vendor_name == "Indifi Business Loan" || vendor_name == 'IIFL Business Loan')
            {
                glidtracker(vendor_name, url_selector);
                gatrack('Apply for loan',vendor_name,'Click');
                clicktracking(vendor_name,self);
            }
            else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity" || vendor_name == "IndiaLends" || vendor_name == "Velocity" || vendor_name == "FinAgg")
            {
                geturldata(vendor_name, url_selector);
                var product_name = vendor_name + " Business Loan"
                gatrack('Apply for loan',product_name,'Click');
                clicktracking(product_name,self);                              
            }
            else
            {
                gatrack('Apply for loan',vendor_name,'Click');
                clicktracking(vendor_name,self);
                window.open(vendor_url,'_blank');
            } 
        }

    }

    checkApplication =  (glid,lender,trigger='') =>{
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var checkAppURL = "https://loans.indiamart.com/functions.php";
        }
        else {
            var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
        }

        var checkAppData = {
            "glid": glid,
            "lender":lender,
            "functionName": "checkApplication",
            // "respFlag":'1'
        };

        var formData = new FormData();
        for (var key in checkAppData) {
            formData.append(key, checkAppData[key]);
        }

        var self = this;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: checkAppURL,
            data: formData,
            timeout: 10000
        }).then((response) => {
            var vendor_name = lender;
            var vendor_url = this.state.vendor_url;
            this.setState({vendor_url:false});
            if (response.data == true) {
                this.state.url_selector = "Continue";
                //console.log("Url selector=>",url_selector);
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" || vendor_name == "Indifi Business Loan" || vendor_name == "IIFL Business Loan")
                {
                    glidtracker(vendor_name, "Continue");
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity" || vendor_name == "IndiaLends" || vendor_name == "Velocity" || vendor_name == "FinAgg")
                {
                    geturldata(vendor_name, "Continue");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(product_name,self);                             
                }
                else
                {
                    if(vendor_name == "LendingKart Business Loan"){
                        vendor_url = "https://www.lendingkart.com/dashboard/auth";
                    }
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                } 

            }
            else{

                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" || vendor_name == "Indifi Business Loan" || vendor_name == "IIFL Business Loan")
                {
                    glidtracker(vendor_name, "Apply Now");
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity" || vendor_name == "IndiaLends" || vendor_name == "Velocity" || vendor_name == "FinAgg")
                {
                    geturldata(vendor_name, "Apply Now");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(product_name,self);                               
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                }
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    submitbtnClick = async() => {
        const phoneNumberInput = document.getElementById('phoneNumber').value;

        var vendor_name = this.state.vendor_name;
        var show = this.state.show;

        // this.setState({vendor_name:false});
        this.setState({url_selector:"Apply Now"});


        gatrack('Non Logged-in User','Submit Button Pressed','Click',phoneNumberInput);

        if(!phoneNumberInput)
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';

            document.getElementById("mobile_err").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else if(phoneNumberInput.length != 10)
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';

            document.getElementById("mobile_err2").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else if(! ['6', '7', '8', '9'].includes(phoneNumberInput.charAt(0)))
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            
            document.getElementById("mobile_err3").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else{
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            document.getElementById("phoneNumber").style.borderColor = '';

            //var self = this;
            
            var host = window.location.hostname;

            if(host == 'loans.indiamart.com'){
                var identifyurl = 'https://loans.indiamart.com/functions.php';
            }
            else{
                var identifyurl = 'https://dev-loans.indiamart.com/functions.php';
            }

            //var identifyurl = 'https://localhost:8000/functions.php';

            var identifyData = {
                "functionName" :"identify",
                "mobile" : phoneNumberInput
            };

            var formData = new FormData();
            for (var key in identifyData) {
                formData.append(key, identifyData[key]);
            }

            var self = this;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: identifyurl,
                data: formData,
                timeout: 10000
            }).then(async (response) => {
                var data_obj = response['data'];
                if (data_obj.code == '200') {
                    var glid = data_obj.DataCookie.glid;
                    var cookiestring = getcookieready(data_obj.DataCookie);
    
                    const expirationDate = new Date();
                    expirationDate.setDate(expirationDate.getDate() + 2);
    
                    const cookie = new Cookies();
                    cookie.set('ImeshVisitor', cookiestring, { path: '/', expires: expirationDate });
    
                    var visitor_name = data_obj.DataCookie.fn;
    
                    if (visitor_name) {
                        self.setState({ username: 'Hi ' + visitor_name });
                    }
    
                    this.setState({ show: false });
    
                    const gstapifunctionAsync = async (glid) => {
                        await this.gstapifunction(glid);
                        this.checkRejected('', '', "Apply Now", this.state.lenderDataState);
                    };
                    if(glid && glid!= ''){
                        await gstapifunctionAsync(glid);
                    }
                }
            }).catch(error => {
                console.error("An error occurred:", error);
            });
        }    
    };

    calcLoan = () => {
        let amtRange = document.getElementById("amtRange").value;
        let tenureRange = document.getElementById("tenureRange").value;
        
        for(let i = 0; i < this.state.lenderDataState.length; i++){
            if (this.state.lenderDataState[i]['image'] !== 'indialends' && this.state.lenderDataState[i]['image'] !== 'finagg' && this.state.lenderDataState[i]['image'] !== 'lkart') {       
                let intRate = document.getElementById("intRate"+i).textContent;
                let rateWithoutPercent = intRate.replace('%', '');            

                let emi = this.emiCalculator(amtRange,rateWithoutPercent,tenureRange);

                document.getElementById("emiStart"+i).innerHTML = ''+emi;
            }
        }

        document.getElementById('amtValue').innerHTML = amtRange;
        document.getElementById('tenureValue').innerHTML = tenureRange*12+' Months';


    }

    calcLoanExp = () => {
        let amtRange = document.getElementById("amtRange").value;
        let tenureRange = document.getElementById("tenureRange").value;
    
        for(let i = 0;i < this.state.lenderLength ;i++){
            if (this.state.lenderDataState[i]['image'] !== 'indialends' && this.state.lenderDataState[i]['image'] !== 'finagg') {
                let rate = document.getElementById("expRate"+i);
                if(rate === null){
                    continue;
                }
                else{
                    let rateWithoutPercent = rate.textContent.replace('%', '');
                    let emi = this.emiCalculator(amtRange,rateWithoutPercent,tenureRange);

                    document.getElementById("emiStart"+i).innerHTML = ''+emi;
                }
            }
        }

        document.getElementById('amtValue').innerHTML = amtRange;
        document.getElementById('tenureValue').innerHTML = tenureRange*12+ ' Months';

    }

    emiCalculator = (p, r, t) => { 
        let emi; 
        // one month interest 
        r = r/(12 * 100); 
        // one month period 
        t = t*12;  
        emi = (p * r * Math.pow(1 + r, t))/(Math.pow(1 + r, t) - 1);
        let roundedVal = Math.round(emi,2);
        return (Math.round(roundedVal/100)*100); 
    }

    stdnumeration = (x) => {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
    }

    handleCloseClick = () => {
        if(this.state.show){
            this.setState({show:false})
        }
    }

    updateScrollPosition() {
        const div = document.getElementById('OfferLenders');
        if (div) {
          const divTop = div.offsetTop;
          const divBottom = divTop + div.offsetHeight - 150;
          const scrollPosition = window.scrollY;
          if (scrollPosition >= divTop && scrollPosition <= divBottom) {
            this.setState({ isScrolledInDiv: true });
          } else {
            this.setState({ isScrolledInDiv: false });
          }
        }
      }

    handleAppAnyClick = () => {
        var glid= getGlid();
        // window.open(this.state.anywayURL,'_blank');
        this.checkApplication(glid,this.state.vendor_name,"appAny")
        this.setState({showReject:false})
        this.setState({anywayURL:''})
    }

    handleExploreClick = () => {
        gatrack('Explore Other Offers',this.state.vendor_name,'Click');
        this.setState({showReject:false});
        this.scrollToElement('lenders')
    }

    scrollToElement = (scrolledto) => {
        const element = document.getElementById(scrolledto);
        
        if (element) {
          const yOffset = element.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: yOffset - 60, behavior: 'smooth' });
        }
    };
    
    render()
    {
        const cookie = new Cookies();        
        const { callerComp } = this.props;
        return (
            

            <>
            {callerComp == "ExploreComponent" ? (
                <>
                <section style={{ margin: '0 auto', paddingTop: '15px', overflow: 'hidden',padding:'5px 10px',marginTop: '10px',width:'91%',paddingBottom:'0px' }}>
                    <div className='row'>
                        <div className='col-8' style={headingstyle}> &#128075; {this.state.username}, Exclusive Loan Offers for your needs </div>
                        <div className='col-4' style={modifydetailsstyle}> <svg style={{ paddingBottom: '5px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" onClick={this.showLoandataModal}>
                            <path d="M10.1909 1.16631L8.7094 2.64782H1.48152V13.0185H11.8521V5.7906L13.3337 4.30908V13.7592C13.3337 14.1683 13.002 14.5 12.5929 14.5H0.740759C0.331653 14.5 0 14.1683 0 13.7592V1.90706C0 1.49796 0.331653 1.16631 0.740759 1.16631H10.1909ZM12.9524 0.5L14 1.54759L7.19062 8.35696L6.14489 8.35874L6.14304 7.30938L12.9524 0.5Z" fill="#02A699" />
                        </svg>
                        {cookie.get("ImeshVisitor") !== '' && cookie.get("ImeshVisitor") !== null && cookie.get("ImeshVisitor") !== undefined && <a style={{ cursor: 'pointer' }} onClick={this.showLoandataModal}>Modify Details</a>} 
                {cookie.get("ImeshVisitor") === '' || cookie.get("ImeshVisitor") === null || cookie.get("ImeshVisitor") === undefined && <a style={{cursor:'pointer'}} onClick={this.showLoandataModal}>Enter Details</a>}
                </div>
                    </div>

                    {/* <div className='lenderfilters' id='random' style={{ minHeight: '165px' }}>
                        <div className='loanamtbtn row'>
                            <div className='col-2' style={{ marginTop: '11px' }}>
                                Loan Amount
                            </div>

                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle1, fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('Upto 5 Lakh', 1)}> Upto 5 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle2, padding: '14px 38px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('5-10 Lakh', 2)}> 5-10 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle3, padding: '14px 33px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('10-20 Lakh', 3)}> 10-20 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle4, whiteSpace: 'nowrap', padding: '14px 22px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('Above 20 Lakh', 4)}> Above 20 Lakh </button>
                            </div>
                            <div className='col-2' style={{ padding: 0, marginRight: '-18px' }}>

                            </div>
                        </div>

                        <div className='row' style={{ marginLeft: '320px' }}>
                            <span style={{ color: 'red', fontSize: '10px', display: 'block', paddingTop: '6px' }}> {this.state.loanAmountErrMsg}</span>
                        </div>


                    </div> */}

                <div style={{display:'flex',backgroundColor:'#FFF',paddingBottom:'0px', padding:'12px 20px',marginTop: '5px'}}>
                    <div style={{width:'45%'}}>
                        <div style={{display:'flex'}}>
                            <div style={{margin:'auto 0px'}}>Loan Amount</div>
                            <div style={{marginLeft:'auto',padding:'2px 20px',borderRadius:'5px',backgroundColor:'#F8F8F8'}} id='amtValue'>{this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan }</div>
                        </div>
                        <div >
                            {   this.state.maxLoan == 1000000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={1000000} defaultValue={1000000} step={100000} id="amtRange" onChange={this.calcLoanExp}/>
                            } 
                            {   this.state.maxLoan == 2000000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={2000000} defaultValue={1000000} step={100000} id="amtRange" onChange={this.calcLoanExp}/>
                            }
                            {   this.state.maxLoan == 500000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={500000} defaultValue={500000} step={50000} id="amtRange" onChange={this.calcLoanExp}/>
                            }
                            <div>
                                <span style={{color:'#757575',fontSize:'14px'}}>0 Lakh</span>
                                <span style={{color:'#757575',fontSize:'14px',float:'right'}}> {this.state.maxLoan == 500000 ? this.state.maxLoan.toString().slice(0,1) : this.state.maxLoan.toString().slice(0,2)} Lakh+</span>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{width:'45%',marginLeft:'auto'}}>
                        <div style={{display:'flex'}}>
                            <div style={{margin:'auto 0px'}}>Tenure</div>
                            <div style={{marginLeft:'auto',padding:'2px 20px',borderRadius:'5px',backgroundColor:'#F8F8F8'}} id='tenureValue'>36 Months</div>
                        </div>
                        <div >
                            <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="1" max="3" defaultValue="3" step="0.5" id="tenureRange" onChange={this.calcLoanExp}/>
                            <div>
                                <span style={{color:'#757575',fontSize:'14px'}}>12 Months</span>
                                <span style={{color:'#757575',fontSize:'14px',float:'right'}}>36 Months</span>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal

                show={this.state.showloandata}
                backdrop="static"
                keyboard={false}
                id="loandata" onHide={this.handleClose} >

                <Modal.Header closeButton style={{ padding: '0px' }}>
                    {cookie.get("ImeshVisitor") !== '' && cookie.get("ImeshVisitor") !== null && cookie.get("ImeshVisitor") !== undefined && <Modal.Title style={modalheaderstyle} >Modify Details</Modal.Title>} 
                {cookie.get("ImeshVisitor") === '' || cookie.get("ImeshVisitor") === null || cookie.get("ImeshVisitor") === undefined && <Modal.Title  style={modalheaderstyle} >Enter Details</Modal.Title>}

                </Modal.Header>
                <hr style={{width:'100%',marginTop:'-5px'}} />
                <Modal.Body >

                    <div className='lenderfilters'>
                        <div className='row'>
                            <div className="col-6 inputboxes" id="pincode_caption">
                                <div style={this.state.pincodeCaptionStyle}> Pincode</div>
                                <input type="text" maxLength="6" placeholder="Enter Pincode" id="pincode" required style={this.state.pincodeInputStyle} value={this.state.pincode} onChange={(event) => this.handlePincodeChange(event)}
                                /></div>
                            <div className="col-6 inputboxes dropdown"

                            >

                                <button type="button" className="btn" style={{ border: 'none', textAlign: 'left', paddingRight: '116px', color: this.state.buttonTextColor4 }} id='loan_amount' onClick={(event) => this.vintageDivClick(event)}>
                                    <div style={this.state.vintageCaptionStyle}> Age of Business</div>
                                    {this.state.vintagetext}</button>
                                <button className="btn dropdown-toggle dropdown-toggle-split" type="button" id="dropdownMenuButton4"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginRight: '-12px', border: 'none' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                        <path d="M5.18865 3.88886L8.85744 0L9.90564 1.11111L5.18865 6.11113L0.47168 1.11111L1.5199 0L5.18865 3.88886Z" fill="#02A699" />
                                    </svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end w-100" aria-labelledby="dropdownMenuButton4">

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="vintage" id="vintage_r1" value="Upto 1 Year" checked={this.state.selectedOption === 'Upto 1 Year'} onClick={() => this.handleRadioClickVintage('Upto 1 Year')} />
                                            <label className="form-check-label" htmlFor="vintage_r1">
                                                {"Upto 1 Year"}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="vintage" id="vintage_r2" value="1-3 Years" checked={this.state.selectedOption === '1-3 Years'} onClick={() => this.handleRadioClickVintage('1-3 Years')} />
                                            <label className="form-check-label" htmlFor="vintage_r2">
                                                1-3 Years
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="vintage" id="vintage_r3" value="Above 3 Years" checked={this.state.selectedOption === 'Above 3 Years'} onClick={() => this.handleRadioClickVintage('Above 3 Years')} />
                                            <label className="form-check-label" htmlFor="vintage_r3">
                                                Above 3 Years
                                            </label>
                                        </div>
                                    </div>


                                    {/* <div className="form-group">
                                <div className="form-check">
                                    <input className="radioclass" type="radio" name="loan_amount" id="vintage_r4" value="12-24 Months" onClick={() => this.handleRadioClickVintage('12-24 Months')}/>
                                    <label className="form-check-label" htmlFor="vintage_r4">
                                    12-24 Months
                                    </label>
                                </div>
                                </div>

                                <div className="form-group">
                                <div className="form-check">
                                    <input className="radioclass" type="radio" name="loan_amount" id="vintage_r5" value="24-36 Months" onClick={() => this.handleRadioClickVintage('24-36 Months')}/>
                                    <label className="form-check-label" htmlFor="vintage_r5">
                                    {"24-36 Months"}
                                    </label>
                                </div>
                                </div>

                                <div className="form-group">
                                <div className="form-check">
                                    <input className="radioclass" type="radio" name="loan_amount" id="vintage_r5" value="> 36 Months" onClick={() => this.handleRadioClickVintage('> 36 Months')}/>
                                    <label className="form-check-label" htmlFor="vintage_r5">
                                    {"> 36 Months"}
                                    </label>
                                </div>
                                </div> */}

                                </div>
                            </div>
                        </div>

                        <div className='row'> <div style={{ marginLeft: '25px', fontSize: '12px', color: 'red' }}>{this.state.pincodewarning} </div></div>
                        <div className='row' style={{ height: '15px' }}> &nbsp;</div>
                        <div className='row'>
                            <div className="col-6 inputboxes dropdown"

                            >
                                <button type="button" className="btn" onClick={(event) => this.turnoverDivClick(event)} style={{ border: 'none', textAlign: 'left', paddingRight: '115px', color: this.state.buttonTextColor3 }}>
                                    <div style={this.state.turnoverCaptionStyle}> Annual Turnover</div>
                                    {this.state.turnovertext}</button>
                                <button className="btn dropdown-toggle dropdown-toggle-split" type="button" id="dropdownMenuButton5"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginRight: '-12px', border: 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                        <path d="M5.18865 3.88886L8.85744 0L9.90564 1.11111L5.18865 6.11113L0.47168 1.11111L1.5199 0L5.18865 3.88886Z" fill="#02A699" />
                                    </svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end w-100" aria-labelledby="dropdownMenuButton5">

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r1" value="Upto 10 Lakh" checked={this.state.selectedOptionTurnover === 'Upto 10 Lakh'} onClick={() => this.handleRadioClickTurnover('Upto 10 Lakh')} />
                                            <label className="form-check-label" htmlFor="turnover_r1">
                                                {"Upto 10 Lakh"}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r2" value="10-25 Lakh" checked={this.state.selectedOptionTurnover === '10-25 Lakh'} onClick={() => this.handleRadioClickTurnover('10-25 Lakh')} />
                                            <label className="form-check-label" htmlFor="turnover_r2">
                                                10-25 Lakh
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r3" value="25-40 Lakh" checked={this.state.selectedOptionTurnover === '25-40 Lakh'} onClick={() => this.handleRadioClickTurnover('25-40 Lakh')} />
                                            <label className="form-check-label" htmlFor="turnover_r3">
                                                25-40 Lakh
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r4" value="40 Lakh-1.5 Cr" checked={this.state.selectedOptionTurnover === '40 Lakh-1.5 Cr'} onClick={() => this.handleRadioClickTurnover('40 Lakh-1.5 Cr')} />
                                            <label className="form-check-label" htmlFor="turnover_r4">
                                                40 Lakh-1.5 Cr
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r5" value="1.5-5 Crore" checked={this.state.selectedOptionTurnover === '1.5-5 Crore'} onClick={() => this.handleRadioClickTurnover('1.5-5 Crore')} />
                                            <label className="form-check-label" htmlFor="turnover_r5">
                                                1.5-5 Crore
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="radioclass" type="radio" name="turnover" id="turnover_r6" value="Above 5 Cr" checked={this.state.selectedOptionTurnover === 'Above 5 Cr'} onClick={() => this.handleRadioClickTurnover('Above 5 Cr')} />
                                            <label className="form-check-label" htmlFor="turnover_r6">
                                                {"Above 5 Cr"}
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </Modal.Body>

                <Modal.Footer style={{ border: 'none', marginLeft: '460px', marginTop: '-20px' }}>
                    <div className='row'>
                        <div className='col-3' id='savebutton' style={modalsavebutton} onClick={() => { this.handleClose(); this.recommend_engine(0); }}>
                            Save
                        </div>
                    </div>
                </Modal.Footer>

                </Modal>


                </section>

                <section style={{height:'auto', background:'#E8F2FF',zIndex:'-1'}}>
                <div className='container sec02 text-center' style={{margin:'0 auto', maxWidth:'1230px', width: '98%'}}>
                <div className="row justify-content-md-center tab">
                    <div className="col-12" id="OfferLenders" style={{width: '91%'}}>
                        <div className={"row thead" + (this.state.isScrolledInDiv ? " headingTilesExp" : "")} style={{height:'40px', overflow:'hidden',fontWeight:'700'}}>
                        <div className="col-2" style={{textAlign: 'center'}}>Partner</div>
                        <div className="col-1 lgOnly tabs" style={{textAlign: 'center'}}>
                            <span style={{whiteSpace:'nowrap'}}>EMI</span>
                        </div>
                        {/* <div className="col-2 lgOnly" style={{textAlign: 'center'}}>EMI/Lakh</div> */}
                        <div className="col-1 tabs" style={{textAlign: 'center'}}>Amount</div>
                        {/* <p style={{fontSize:'10px', color:'#A9A9A9'}}>Upto</p> */}
                        <div className="col-1 tabs" style={{textAlign: 'center'}}>
                            <span style={{whiteSpace:'nowrap'}}>Interest</span> 
                        </div>
                        <div className="col-1 lgOnly tabs" style={{textAlign: 'center', whiteSpace:'nowrap'}}>Fees </div>
                        {/* <div className="col-3 lgOnly tabs" style={{textAlign: 'center',minWidth:'140px'}}>Eligibility Criterion</div> */}
                        <div className="col-2 lgOnly" style={{textAlign: 'center',paddingLeft:'0px',paddingRight:'0px'}}>Approval Chances</div>

                        {/* <div className="col-1 px-0" style={{border: 'none'}}></div> */}
                        </div>

                        

                        <div id="lenders" className={(this.state.isScrolledInDiv ? "lenderTop" : "")}>
                                {this.state.dynamicContent}
                        </div>


                        <div style={{ float: 'right', fontSize: '11px',paddingTop: '10px', color: '#757575',fontFamily: 'Arial',fontSize: '11px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal',textTransform: 'capitalize'}}>*The ROI and processing fee is prone to fluctuation basis lender's internal credit and risk policy.</div>


                    </div>
            </div>
                </div>
                
                        </section>

                        <Modal

                            show={this.state.show}
                            backdrop="static"
                            keyboard={false}
                            id="loginpopupmodal" style={{ marginTop: '200px', padding: '10px' }}>

                            <Modal.Header style={{backgroundColor: '#2E3192', padding: '6px 16px'}}>
                                <Modal.Title className="fs-5" style={{color: '#ffff', fontWeight: '700', fontSize: '20px !important'}} >Sign In</Modal.Title>
                                <button type="button" className="custom-close-button" onClick={this.handleCloseClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '4px', right: '16px' }}>
                                    &times;
                                </button>
                            </Modal.Header>
                            <Modal.Body >
                                <label htmlFor="phoneNumber" style={{ marginBottom: '10px', marginLeft: '3px' }}>Mobile Number linked to IndiaMART account</label>
                                <input type="tel" maxLength="10" placeholder="Enter your IndiaMART account mobile number" id="phoneNumber" className="un2_s" style={{ backgroundColor: 'rgb(255, 255, 255)', outline: 'none', boxSizing: 'content-box', paddingLeft: '5px', width: '98%', height: '30px', margin: 'auto' }} pattern="[0-9]{10}" required />
                                <span id="mobile_err" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Please enter mobile number</span>
                                <span id="mobile_err2" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Please enter ten digit mobile number</span>
                                <span id="mobile_err3" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Mobile Number should start with 6,7,8 or 9</span>
                                <button id="submitBtn" className="continue_s" style={{ padding: '8px 0px', width: '180px', backgroundColor: 'rgb(0, 166, 153)', margin: '15px auto', display: 'block', marginBottom: '5px' }} onClick={this.submitbtnClick} >Submit</button>

                            </Modal.Body>


                        </Modal>

                        <Modal 
                        show={this.state.showReject}
                        // show={true}
                        backdrop="static"
                        keyboard={false}
                        id="rejectModal" 
                        centered
                        contentClassName="custom-modal-content"
                        >
                            <div style={{height:'250px',backgroundColor:'#FFF',display:'flex',borderRadius:'12px',overflow:'hidden'}}>
                                <div style={{backgroundColor:'#FBFBFB',width:'33%',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={sad_face} alt='sad icon' style={{width:'110px'}}/>
                                </div>
                                <div style={{width:'67%',marginLeft:'20px'}}>
                                    <img src={'https://loans.imimg.com/images/loans/headerimages/cross_icon.png'} alt='cross' style={{width:'20px',height:'20px',float:'right',marginRight:'10px',marginTop:'10px',cursor:'pointer'}} onClick={()=>{this.setState({showReject:false})}}/>

                                    <div style={{marginTop:'40px'}}>
                                        <div style={{fontSize:'18px',lineHeight:'26.63px',fontWeight:'700',color:'#1B1D1D',marginTop:'20px'}}>Low Approval Chance!</div>

                                        <div style={{fontSize:'14px',color:'#757575',marginTop:'10px',paddingRight:'30px'}}>
                                        <span style={{fontWeight:'700'}}>{this.state.rejectLenName} {this.state.showRejectReason}</span> Hence, you’ve very low chances of approval.
                                        </div>
                                    </div>

                                    <div style={{marginTop:'10px'}}>
                                        <a className='exploreOther' onClick={this.handleExploreClick}> Explore Other Offers</a>
                                            <div style={{marginTop:'-5px'}}>
                                                <a onClick={this.handleAppAnyClick} style={{color:'#036efd',textDecoration:'underline',cursor:'pointer',fontSize:'14px'}}>Apply Anyway</a>
                                            </div>
                                    </div>

                                </div>
                            </div>
                        </Modal>

                </>
            ) :
                (                
                <>
                <section style={{ margin: '0 auto', paddingTop: '15px', overflow: 'hidden', padding:'5px 10px',marginTop: '0px', maxWidth:callerComp == 'Exp' ? '' : '1130px',width:callerComp == 'Exp' ? '90%' : '' ,paddingTop:'0px',paddingBottom:'0px'}}>
                    <div className='row'>
                        <div className='col-8' style={headingstyle}> &#128075; {this.state.username}, Exclusive Loan Offers for your needs </div>
                        <div className='col-4' style={modifydetailsstyle}> <svg style={{ paddingBottom: '5px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" onClick={this.showLoandataModal}>
                            <path d="M10.1909 1.16631L8.7094 2.64782H1.48152V13.0185H11.8521V5.7906L13.3337 4.30908V13.7592C13.3337 14.1683 13.002 14.5 12.5929 14.5H0.740759C0.331653 14.5 0 14.1683 0 13.7592V1.90706C0 1.49796 0.331653 1.16631 0.740759 1.16631H10.1909ZM12.9524 0.5L14 1.54759L7.19062 8.35696L6.14489 8.35874L6.14304 7.30938L12.9524 0.5Z" fill="#02A699" />
                        </svg>
                        {cookie.get("ImeshVisitor") !== '' && cookie.get("ImeshVisitor") !== null && cookie.get("ImeshVisitor") !== undefined && <a style={{ cursor: 'pointer' }} onClick={this.showLoandataModal}>Modify Details</a>} 
                {cookie.get("ImeshVisitor") === '' || cookie.get("ImeshVisitor") === null || cookie.get("ImeshVisitor") === undefined && <a style={{cursor:'pointer'}} onClick={this.showLoandataModal}>Enter Details</a>}
                </div>
                    </div>

                    {/* <div className='lenderfilters' id='random' style={{ minHeight: '165px' }}>
                        <div className='loanamtbtn row'>
                            <div className='col-2' style={{ marginTop: '11px' }}>
                                Loan Amount
                            </div>

                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle1, fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('Upto 5 Lakh', 1)}> Upto 5 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle2, padding: '14px 38px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('5-10 Lakh', 2)}> 5-10 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle3, padding: '14px 33px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('10-20 Lakh', 3)}> 10-20 Lakh </button>
                            </div>
                            <div className='col-2'>
                                <button style={{ ...this.state.loanAmountStyle4, whiteSpace: 'nowrap', padding: '14px 22px', fontFamily: 'Arial', fontSize: '14px' }} onClick={() => this.selectloanamount('Above 20 Lakh', 4)}> Above 20 Lakh </button>
                            </div>
                            <div className='col-2' style={{ padding: 0, marginRight: '-18px' }}>

                            </div>
                        </div>

                        <div className='row' style={{ marginLeft: '320px' }}>
                            <span style={{ color: 'red', fontSize: '10px', display: 'block', paddingTop: '6px' }}> {this.state.loanAmountErrMsg}</span>
                        </div>


                    </div> */}

                <div style={{display:'flex',backgroundColor:'##F8F9FA',padding:'20px',paddingBottom:'0px', paddingTop:'8px',marginTop: callerComp == 'Exp' ? '10px' : '0px',height:'84px' }}>
                    <div style={{width:'45%'}}>
                        <div style={{display:'flex'}}>
                            <div style={{margin:'auto 0px'}}>Loan Amount</div>
                            <div style={{marginLeft:'auto',padding:'2px 20px',borderRadius:'5px',backgroundColor:'#F8F8F8'}} id='amtValue'>{this.state.maxLoan == 2000000 ? 1000000 : this.state.maxLoan }</div>
                        </div>
                        <div >
                            {   this.state.maxLoan == 1000000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={1000000} defaultValue={1000000} step={100000} id="amtRange" onChange={this.calcLoan}/>
                            } 
                            {   this.state.maxLoan == 2000000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={2000000} defaultValue={1000000} step={100000} id="amtRange" onChange={this.calcLoan}/>
                            }
                            {   this.state.maxLoan == 500000 &&
                                <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="0" max={500000} defaultValue={500000} step={50000} id="amtRange" onChange={this.calcLoan}/>
                            }
                            <div style={{marginTop:'-5px'}}>
                                <span style={{color:'#757575',fontSize:'14px'}}>0 Lakh</span>
                                <span style={{color:'#757575',fontSize:'14px',float:'right'}}> {this.state.maxLoan == 500000 ? this.state.maxLoan.toString().slice(0,1) : this.state.maxLoan.toString().slice(0,2)} Lakh+</span>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{width:'45%',marginLeft:'auto'}}>
                        <div style={{display:'flex'}}>
                            <div style={{margin:'auto 0px'}}>Tenure</div>
                            <div style={{marginLeft:'auto',padding:'2px 20px',borderRadius:'5px',backgroundColor:'#F8F8F8'}} id='tenureValue'>36 Months</div>
                        </div>
                        <div >
                            <input type="range" labels-list="loan_amount_labels" className="form-control-range range_elem" min="1" max="3" defaultValue="3" step="0.5" id="tenureRange" onChange={this.calcLoan}/>
                            <div style={{marginTop:'-5px'}}>
                                <span style={{color:'#757575',fontSize:'14px'}}>12 Months</span>
                                <span style={{color:'#757575',fontSize:'14px',float:'right'}}>36 Months</span>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal

                    show={this.state.showloandata}
                    backdrop="static"
                    keyboard={false}
                    id="loandata" onHide={this.handleClose} >

                    <Modal.Header closeButton style={{ padding: '0px' }}>
                        {cookie.get("ImeshVisitor") !== '' && cookie.get("ImeshVisitor") !== null && cookie.get("ImeshVisitor") !== undefined && <Modal.Title style={modalheaderstyle} >Modify Details</Modal.Title>} 
                    {cookie.get("ImeshVisitor") === '' || cookie.get("ImeshVisitor") === null || cookie.get("ImeshVisitor") === undefined && <Modal.Title  style={modalheaderstyle} >Enter Details</Modal.Title>}

                    </Modal.Header>
                    <hr style={{width:'100%',marginTop:'-5px'}} />
                    <Modal.Body >

                        <div className='lenderfilters'>
                            <div className='row'>
                                <div className="col-6 inputboxes" id="pincode_caption">
                                    <div style={this.state.pincodeCaptionStyle}> Pincode</div>
                                    <input type="text" maxLength="6" placeholder="Enter Pincode" id="pincode" required style={this.state.pincodeInputStyle} value={this.state.pincode} onChange={(event) => this.handlePincodeChange(event)}
                                    /></div>
                                <div className="col-6 inputboxes dropdown"

                                >

                                    <button type="button" className="btn" style={{ border: 'none', textAlign: 'left', paddingRight: '116px', color: this.state.buttonTextColor4 }} id='loan_amount' onClick={(event) => this.vintageDivClick(event)}>
                                        <div style={this.state.vintageCaptionStyle}> Age of Business</div>
                                        {this.state.vintagetext}</button>
                                    <button className="btn dropdown-toggle dropdown-toggle-split" type="button" id="dropdownMenuButton4"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginRight: '-12px', border: 'none' }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                            <path d="M5.18865 3.88886L8.85744 0L9.90564 1.11111L5.18865 6.11113L0.47168 1.11111L1.5199 0L5.18865 3.88886Z" fill="#02A699" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end w-100" aria-labelledby="dropdownMenuButton4">

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="vintage" id="vintage_r1" value="Upto 1 Year" checked={this.state.selectedOption === 'Upto 1 Year'} onClick={() => this.handleRadioClickVintage('Upto 1 Year')} />
                                                <label className="form-check-label" htmlFor="vintage_r1">
                                                    {"Upto 1 Year"}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="vintage" id="vintage_r2" value="1-3 Years" checked={this.state.selectedOption === '1-3 Years'} onClick={() => this.handleRadioClickVintage('1-3 Years')} />
                                                <label className="form-check-label" htmlFor="vintage_r2">
                                                    1-3 Years
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="vintage" id="vintage_r3" value="Above 3 Years" checked={this.state.selectedOption === 'Above 3 Years'} onClick={() => this.handleRadioClickVintage('Above 3 Years')} />
                                                <label className="form-check-label" htmlFor="vintage_r3">
                                                    Above 3 Years
                                                </label>
                                            </div>
                                        </div>


                                        {/* <div className="form-group">
                                    <div className="form-check">
                                        <input className="radioclass" type="radio" name="loan_amount" id="vintage_r4" value="12-24 Months" onClick={() => this.handleRadioClickVintage('12-24 Months')}/>
                                        <label className="form-check-label" htmlFor="vintage_r4">
                                        12-24 Months
                                        </label>
                                    </div>
                                    </div>

                                    <div className="form-group">
                                    <div className="form-check">
                                        <input className="radioclass" type="radio" name="loan_amount" id="vintage_r5" value="24-36 Months" onClick={() => this.handleRadioClickVintage('24-36 Months')}/>
                                        <label className="form-check-label" htmlFor="vintage_r5">
                                        {"24-36 Months"}
                                        </label>
                                    </div>
                                    </div>

                                    <div className="form-group">
                                    <div className="form-check">
                                        <input className="radioclass" type="radio" name="loan_amount" id="vintage_r5" value="> 36 Months" onClick={() => this.handleRadioClickVintage('> 36 Months')}/>
                                        <label className="form-check-label" htmlFor="vintage_r5">
                                        {"> 36 Months"}
                                        </label>
                                    </div>
                                    </div> */}

                                    </div>
                                </div>
                            </div>

                            <div className='row'> <div style={{ marginLeft: '25px', fontSize: '12px', color: 'red' }}>{this.state.pincodewarning} </div></div>
                            <div className='row' style={{ height: '15px' }}> &nbsp;</div>
                            <div className='row'>
                                <div className="col-6 inputboxes dropdown"

                                >
                                    <button type="button" className="btn" onClick={(event) => this.turnoverDivClick(event)} style={{ border: 'none', textAlign: 'left', paddingRight: '115px', color: this.state.buttonTextColor3 }}>
                                        <div style={this.state.turnoverCaptionStyle}> Annual Turnover</div>
                                        {this.state.turnovertext}</button>
                                    <button className="btn dropdown-toggle dropdown-toggle-split" type="button" id="dropdownMenuButton5"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginRight: '-12px', border: 'none' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                            <path d="M5.18865 3.88886L8.85744 0L9.90564 1.11111L5.18865 6.11113L0.47168 1.11111L1.5199 0L5.18865 3.88886Z" fill="#02A699" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end w-100" aria-labelledby="dropdownMenuButton5">

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r1" value="Upto 10 Lakh" checked={this.state.selectedOptionTurnover === 'Upto 10 Lakh'} onClick={() => this.handleRadioClickTurnover('Upto 10 Lakh')} />
                                                <label className="form-check-label" htmlFor="turnover_r1">
                                                    {"Upto 10 Lakh"}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r2" value="10-25 Lakh" checked={this.state.selectedOptionTurnover === '10-25 Lakh'} onClick={() => this.handleRadioClickTurnover('10-25 Lakh')} />
                                                <label className="form-check-label" htmlFor="turnover_r2">
                                                    10-25 Lakh
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r3" value="25-40 Lakh" checked={this.state.selectedOptionTurnover === '25-40 Lakh'} onClick={() => this.handleRadioClickTurnover('25-40 Lakh')} />
                                                <label className="form-check-label" htmlFor="turnover_r3">
                                                    25-40 Lakh
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r4" value="40 Lakh-1.5 Cr" checked={this.state.selectedOptionTurnover === '40 Lakh-1.5 Cr'} onClick={() => this.handleRadioClickTurnover('40 Lakh-1.5 Cr')} />
                                                <label className="form-check-label" htmlFor="turnover_r4">
                                                    40 Lakh-1.5 Cr
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r5" value="1.5-5 Crore" checked={this.state.selectedOptionTurnover === '1.5-5 Crore'} onClick={() => this.handleRadioClickTurnover('1.5-5 Crore')} />
                                                <label className="form-check-label" htmlFor="turnover_r5">
                                                    1.5-5 Crore
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="radioclass" type="radio" name="turnover" id="turnover_r6" value="Above 5 Cr" checked={this.state.selectedOptionTurnover === 'Above 5 Cr'} onClick={() => this.handleRadioClickTurnover('Above 5 Cr')} />
                                                <label className="form-check-label" htmlFor="turnover_r6">
                                                    {"Above 5 Cr"}
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </Modal.Body>

                    <Modal.Footer style={{ border: 'none', marginLeft: '460px', marginTop: '-20px' }}>
                        <div className='row'>
                            <div className='col-3' id='savebutton' style={modalsavebutton} onClick={() => { this.handleClose(); this.recommend_engine(0); }}>
                                Save
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>


                </section>

                <section style={{height:'auto', background:'#CDE3FF',zIndex:'-1'}}>
                <div className='container sec02 text-center' style={{margin:'0 auto', maxWidth:'1230px', width: '98%'}}>
                <div className="row justify-content-md-center tab" ref = {this.props.forwardedRef}>
                    <div className="col-12" id="OfferLenders" style={{width: '91%'}}>
                        <div className={"row thead" + (this.state.isScrolledInDiv ? " headingTilesHome" : "")} style={{height:'40px', overflow:'hidden',fontWeight:'700'}}>
                        <div className="col-2" style={{textAlign: 'center'}}>Partner</div>
                        <div className="col-1 lgOnly tabs" style={{textAlign: 'center'}}>
                            <span style={{whiteSpace:'nowrap'}}>EMI</span>
                        </div>
                        {/* <div className="col-2 lgOnly" style={{textAlign: 'center'}}>EMI/Lakh</div> */}
                        <div className="col-1 tabs" style={{textAlign: 'center'}}>Amount</div>
                        {/* <p style={{fontSize:'10px', color:'#A9A9A9'}}>Upto</p> */}
                        <div className="col-1 tabs" style={{textAlign: 'center'}}>Interest</div>
                        <div className="col-1 lgOnly tabs" style={{textAlign: 'center', whiteSpace:'nowrap'}}>Fees </div>
                        {/* <div className="col-3 lgOnly tabs" style={{textAlign: 'center',minWidth:'140px'}}>Eligibility Criterion</div> */}
                        <div className="col-2 lgOnly" style={{textAlign: 'center',paddingLeft:'0px',paddingRight:'0px'}}>Approval Chances</div>

                        {/* <div className="col-1 px-0" style={{border: 'none'}}></div> */}
                        </div>

                        

                        <div id="lenders"  className={(this.state.isScrolledInDiv ? "lenderTop" : "")}>
                                {this.state.dynamicContent}
                        </div>


                        <div style={{ float: 'right', fontSize: '11px',paddingTop: '10px', color: '#757575',fontFamily: 'Arial',fontSize: '11px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal',textTransform: 'capitalize'}}>*The ROI and processing fee is prone to fluctuation basis lender's internal credit and risk policy.</div>


                    </div>
            </div>
                </div>
                
                        </section>

                        <Modal

                            show={this.state.show}
                            backdrop="static"
                            keyboard={false}
                            id="loginpopupmodal" 
                            style={{ marginTop: '200px', padding: '10px' }}
                            >

                            <Modal.Header style={{backgroundColor: '#2E3192', padding: '6px 16px'}}>
                                <Modal.Title className="fs-5" style={{color: '#ffff', fontWeight: '700', fontSize: '20px !important'}} >Sign In</Modal.Title>
                                <button type="button" className="custom-close-button" onClick={this.handleCloseClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '4px', right: '16px' }}>
                                    &times;
                                </button>
                            </Modal.Header>
                            <Modal.Body >
                                <label htmlFor="phoneNumber" style={{ marginBottom: '10px', marginLeft: '3px' }}>Mobile Number linked to IndiaMART account</label>
                                <input type="tel" maxLength="10" placeholder="Enter your IndiaMART account mobile number" id="phoneNumber" className="un2_s" style={{ backgroundColor: 'rgb(255, 255, 255)', outline: 'none', boxSizing: 'content-box', paddingLeft: '5px', width: '98%', height: '30px', margin: 'auto' }} pattern="[0-9]{10}" required />
                                <span id="mobile_err" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Please enter mobile number</span>
                                <span id="mobile_err2" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Please enter ten digit mobile number</span>
                                <span id="mobile_err3" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Mobile Number should start with 6,7,8 or 9</span>
                                <button id="submitBtn" className="continue_s" style={{ padding: '8px 0px', width: '180px', backgroundColor: 'rgb(0, 166, 153)', margin: '15px auto', display: 'block', marginBottom: '5px' }} onClick={this.submitbtnClick} >Submit</button>

                            </Modal.Body>


                        </Modal>
                        
                        <Modal 
                        show={this.state.showReject}
                        // show={true}
                        backdrop="static"
                        keyboard={false}
                        id="rejectModal" 
                        centered
                        contentClassName="custom-modal-content"
                        >
                            <div style={{height:'250px',backgroundColor:'#FFF',display:'flex',borderRadius:'12px',overflow:'hidden'}}>
                                <div style={{backgroundColor:'#FBFBFB',width:'33%',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={sad_face} alt='sad icon' style={{width:'110px'}}/>
                                </div>
                                <div style={{width:'67%',marginLeft:'20px'}}>
                                    <img src={'https://loans.imimg.com/images/loans/headerimages/cross_icon.png'} alt='cross' style={{width:'20px',height:'20px',float:'right',marginRight:'10px',marginTop:'10px',cursor:'pointer'}} onClick={()=>{this.setState({showReject:false})}}/>

                                    <div style={{marginTop:'40px'}}>
                                        <div style={{fontSize:'18px',lineHeight:'26.63px',fontWeight:'700',color:'#1B1D1D',marginTop:'20px'}}>Low Approval Chance!</div>

                                        <div style={{fontSize:'14px',color:'#757575',marginTop:'10px',paddingRight:'30px'}}>
                                        <span style={{fontWeight:'700'}}>{this.state.rejectLenName} {this.state.showRejectReason}</span> Hence, you’ve very low chances of approval.
                                        </div>
                                    </div>

                                    <div style={{marginTop:'10px'}}>
                                        <a className='exploreOther' onClick={this.handleExploreClick}> Explore Other Offers</a>
                                            <div style={{marginTop:'-5px'}}>
                                                <a onClick={this.handleAppAnyClick} style={{color:'#036efd',textDecoration:'underline',cursor:'pointer',fontSize:'14px'}}>Apply Anyway</a>
                                            </div>
                                    </div>

                                </div>
                            </div>
                        </Modal>
                        
                </>
            )

}

</>
        );
    }

    handleglidclick = (vendor_name,vendor_url) => {
        
        if(this.state.ifglid)
        {
            this.logincheck(vendor_name,vendor_url);
        }
    }

}


export default React.forwardRef((props, ref) => <LenderComponent forwardedRef={ref} {...props} />);
